import React, { useCallback, useEffect, useState } from "react";
import { IconButton, InputBase } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { debounce } from "../../services/helpers";
import DeliveryMainHeader from "../../components/DeliveryMainHeader";
import { useFetchAllGetForAgent } from "../../services/apis/deliveryRequests";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useImageStore } from "../../zustand/useImageStore";

const DeliveryRequests = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // Hook to manage URL query parameters
  const { clearImages, clearNationalId } = useImageStore();
  const handleNavigation = (item) => {
    console.log("clicked ", item);
    navigate(`/HandoverRequests/${item.id}`, { state: item });
  };
  const [queryParameters, setQueryParameters] = useState({
    query: searchParams.get("query") || "",
    limit: Number(searchParams.get("limit")) || 10,
  });
  const { data, isFetching, refetch } = useFetchAllGetForAgent(queryParameters);
  const handleSearch = useCallback(
    debounce((value) => {
      setQueryParameters((prev) => ({
        ...prev,
        query: value,
      }));
    }, 500),
    []
  );
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat("ar-EG", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      numberingSystem: "latn",
    }).format(date);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat("ar-EG", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      numberingSystem: "latn",
    }).format(date);
  };
  useEffect(() => {
    clearImages();
    clearNationalId();
  }, []);
  return (
    <div className="w-full h-full pb-[10%]">
      <DeliveryMainHeader label={"مرحبا بك"} />
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <main className="w-full" dir={"rtl"}>
          <div className="w-full flex items-center justify-center mt-8">
            <div
              className="flex items-center rounded-xl bg-[#FFF] p-2 w-[90%] md:w-3/5"
              style={{
                boxShadow:
                  "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
              }}
            >
              <IconButton type="button" aria-label="search">
                <img
                  alt="search-normal"
                  src={`../../assets/search-normal.png`}
                />
              </IconButton>
              <InputBase
                onChange={(event) => {
                  handleSearch(event.target.value);
                }}
                sx={{ ml: 1, flex: 1, fontSize: "16px" }}
                placeholder="بحث برقم الهاتف او الرقم القومي."
              />
            </div>
          </div>
          {data?.result?.length === 0 && (
            <div className="w-full flex items-center justify-center mt-[50%]">
              <p>لا يوجد طلب استلام حاليا</p>
            </div>
          )}
          {data?.result?.length > 0 && (
            <div className="w-full flex items-center flex-col justify-center mt-4">
              {data?.result?.map((item, index) => (
                <div
                  onClick={() => handleNavigation(item)}
                  key={item?.id}
                  className="flex  w-[90%] rounded-xl bg-[#FFF] px-4 py-8 mt-4 cursor-pointer"
                  style={{
                    boxShadow:
                      "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
                  }}
                >
                  <div className="flex flex-col w-full">
                    <div className="flex items-center justify-between w-full ">
                      <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                        رقم الهاتف :
                      </p>
                      <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                        {"0" + item?.phone}
                      </p>
                    </div>
                    <div className="mt-4 flex items-center justify-between w-full">
                      <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                        اسم العميل :
                      </p>
                      <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                        {item?.national_name}
                      </p>
                    </div>
                    <div className="mt-4 flex items-center justify-between w-full">
                      <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                        تاريخ التسليم :
                      </p>
                      {item?.receive_time && (
                        <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                          {formatDate(item?.receive_time)}
                        </p>
                      )}
                    </div>
                    <div className="mt-4 flex items-center justify-between w-full">
                      <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                        معاد التسليم :
                      </p>
                      {item?.receive_time && (
                        <p className="text-[#333333] text-sm font-normal font-ibmArabic">
                          {formatTime(item?.receive_time)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </main>
      )}
    </div>
  );
};

export default DeliveryRequests;
