import { useMutation, useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

export const fetchAllGetForAgent = async (q) => {
  try {
    const response = await axios.get(`/api/receiveGold/getForAgent`, {
      params: {
        page: q.page,
        limit: q.limit,
        query: q.query.startsWith("0") ? q.query.slice(1) : q.query,
      },
    });
    return response.data.data; // Assuming you want to return the data part of the response
  } catch (error) {
    throw error;
  }
};

export const useFetchAllGetForAgent = (q) => {
  return useQuery(["fetchAllGetForAgent", q], () => fetchAllGetForAgent(q), {
    enabled: !!q,
  });
};

const SendReceiveGoldOtp = async ({ id }) => {
  try {
    const response = await axios.get(`/api/receiveGold/sendOtp/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const useSendReceiveGoldOtp = () => {
  return useMutation(SendReceiveGoldOtp);
};
const SendReceiveGoldCheckOtp = async ({ body }) => {
  try {
    const response = await axios.post(`/api/receiveGold/checkOtp`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const useSendReceiveGoldCheckOtp = () => {
  return useMutation(SendReceiveGoldCheckOtp);
};
const uploadEditAgent = async ({ id, body }) => {
  const response = await axios.put(`/api/receiveGold/editAgent/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

// Define the useKyc hook
export const useUploadEditAgent = () => {
  return useMutation({
    mutationFn: uploadEditAgent,
  });
};
const missedAgentRequests = async ({ id, body }) => {
  try {
    const response = await axios.put(
      `/api/receiveGold/missedAgent/${id}`,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const useMissedAgentRequests = () => {
  return useMutation(missedAgentRequests);
};
