import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DeliveryMainHeader from "../../components/DeliveryMainHeader";
import { imageBaseUrl } from "../../services/config";
import {
  useSendReceiveGoldCheckOtp,
  useSendReceiveGoldOtp,
} from "../../services/apis/deliveryRequests";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import ReusableSnackbar from "../../components/ReusableSnackbar";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // Hook to manage URL query parameters
  const location = useLocation();
  const item = location.state;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const [secondsLeft, setSecondsLeft] = useState(180);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const checkOtpMutation = useSendReceiveGoldCheckOtp();
  const sendOtpMutation = useSendReceiveGoldOtp();

  const [otp, setOtp] = useState(""); // Store user input OTP

  const handleCheckOtp = (item) => {
    console.log("clicked ", item);
    setIsSubmitting(true);

    checkOtpMutation.mutate(
      {
        body: {
          phone: item.phone,
          otp: otp,
        },
      }, // Pass the item ID to the mutation
      {
        onSuccess: () => {
          setIsSubmitting(false);
          navigate(`/UploadNationalId/${item.id}`, { state: { ...item, otp } });
        },
        onError: (error) => {
          setIsSubmitting(false);
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message || "Failed to get otp.",
            })
          );
        },
      }
    );
  };
  const handleNavigation = (item) => {
    console.log("clicked ", item);
    navigate(`/NotReceivedRequest/${item.id}`, { state: item });
  };
  const handleVerifyNavigation = (item) => {
    console.log("clicked ", item);
    navigate(`/VerifyId/${item.id}`, { state: item });
  };
  const handleResend = () => {
    sendOtpMutation.mutate(
      { id: item.id },
      {
        onSuccess: () => {
          setSecondsLeft(180);
          setIsTimerActive(true);
        },
        onError: (error) => {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message || "Failed to resend otp.",
            })
          );
        },
      }
    );
  };
  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  useEffect(() => {
    let timer = null;

    if (isTimerActive && secondsLeft > 0) {
      timer = setInterval(() => {
        setSecondsLeft((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (secondsLeft === 0) {
      setIsTimerActive(false); // Timer has ended
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [secondsLeft, isTimerActive]);
  return (
    <div className="w-full h-full pb-[8%]">
      <DeliveryMainHeader
        label={"كود التسليم"}
        back={true}
        onClick={() => navigate(-1)}
      />
      <main className="w-full" dir={"rtl"}>
        <div className="bg-red  flex items-center flex-col justify-center mt-6 ">
          <div className=" w-[90%]">
            <p className="text-[#404040] text-base font-normal font-ibmArabic mt-10">
              يرجى طلب رمز التأكيد من العميل لإتمام عملية تسليم الذهب.
            </p>
          </div>
          <div className="w-full flex items-center flex-col justify-center mt-10">
            <p className="text-[#595959] text-xs font-normal font-ibmArabic flex self-start ms-[6%] ">
              كود التأكيد للتسليم
            </p>
            <input
              maxLength={4}
              type="text"
              placeholder="إدخال كود التأكيد للتسليم"
              style={{
                fontSize: "16px",
                boxShadow:
                  "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
              }}
              className="w-[90%] h-12  rounded-lg px-4 mt-2 text-right text-black focus:outline-none focus:ring-2 focus:ring-[#917244]"
              value={otp}
              onChange={(e) => setOtp(e.target.value.replace(/[^0-9]/g, ""))}
            />
            <div className="mt-12 w-full flex items-center justify-center flex-col font-ibmArabic">
              <p>لم يتم استلام كود التسليم ؟</p>
              {isTimerActive ? (
                <p className="text-[#917244] text-sm font-ibmArabic mt-4">
                  {formatTime(secondsLeft)}{" "}
                </p>
              ) : (
                <p
                  className="text-[#917244] text-sm cursor-pointer underline font-ibmArabic mt-4"
                  onClick={handleResend}
                >
                  إعادة ارسال الكود
                </p>
              )}
            </div>
          </div>
          <div className="bg-red w-full flex items-center flex-col justify-center mt-6">
            <Button
              disabled={isSubmitting || otp.trim().length !== 4}
              onClick={() => {
                handleCheckOtp(item);
              }}
              sx={{
                "&:hover": { backgroundColor: "#917244" },
                backgroundColor: "#917244",
                width: "90%",
                height: "48px",
                borderRadius: "12px",
                color: "#ffffff",
                fontSize: "16px",
                marginTop: "5%",
                fontFamily: "ibmArabic",
              }}
            >
              تأكيد التسليم
            </Button>
          </div>
        </div>
      </main>
      <ReusableSnackbar />
    </div>
  );
};

export default VerifyOtp;
