import { Box, Button, Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomDialog from "../../../components/CustomDialog";
import mark from "../../../svg/newdanger.svg";
import { useUpdateDetailsReceiveRequests } from "../../../services/apis/ReceiveGold";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";

const PreparingDialog = ({
  handleDialogClose,
  DialogOpen,
  setDialogOpen,
  requestId,
  refetch,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const mutation = useUpdateDetailsReceiveRequests();
  const dispatch = useDispatch();

  const handleConfirm = () => {
    setIsSubmitting(true);

    mutation.mutate(
      { id: requestId, body: { status: 2 } },
      {
        onSuccess: () => {
          setIsSubmitting(false);
          setDialogOpen(false);
          refetch();
          setSuccessDialogOpen(true);
        },
        onError: (error) => {
          setIsSubmitting(false);
          dispatch(
            setData({
              openSnack: true,
              message:
                error.response.data.message || "Failed to update request.",
            })
          );
        },
      }
    );
  };
  return (
    <>
      <Dialog
        maxWidth="700px"
        open={DialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "700px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 3,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={handleDialogClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <p className="text-[#404040] flex justify-start w-[100%] font-medium text-2xl ">
              Change Request Status
            </p>

            <Box
              sx={{
                width: "80px",
                height: "80px",
              }}
            >
              <img src={mark} alt="mark" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  color: "#404040",
                  fontWeight: "500",
                  fontSize: "24px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Are you sure you want to mark this Request as a Mark as
                Preparing for Pickup ?
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleConfirm}
                disabled={isSubmitting}
              >
                Confirm
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={handleDialogClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title={"Request Status has been changed Successfully"}
        message=""
        buttonText="Back to Receive Requests"
      />
    </>
  );
};

export default PreparingDialog;
