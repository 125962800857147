import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RxHamburgerMenu } from "react-icons/rx";
import { toggleSidebar } from "../store/slices/sidebarSlice";
import useWindowSize from "../hooks/useWindowSize";
import newarrowright from "../svg/newarrowright.svg";

const DeliveryMainHeader = ({ label, back, onClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  return (
    <div
      className={`flex 
     items-center pt-[12%]`}
    >
      <RxHamburgerMenu
        color="#333333"
        size={24}
        onClick={() => {
          dispatch(toggleSidebar());
        }}
        className={` mr-auto ml-6
          ${width > 768 ? "hidden" : "block"}
          `}
      />
      <div className="flex items-center justify-end w-full mx-6">
        <p className="text-[#333333] text-2xl font-medium font-ibmArabic">
          {label}
        </p>
        {back && (
          <img
            src={newarrowright}
            alt="back"
            className="ms-3 cursor-pointer"
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};

export default DeliveryMainHeader;
