import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import useUserFilterData from "../../../zustand/useUserFilterData";
const MoneyDateFilter = () => {
  const { date_from, date_to, setDateFrom, setDateTo } = useUserFilterData();
  return (
    <div className="flex-col mt-4 ">
      <p className="text-[#595959] font-medium font-ibmArabic text-lg mb-4">
        Date
      </p>
      <p className="text-[#595959] text-sm font-ibmArabic mb-2">From</p>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* Customized From Date Picker */}
        <DatePicker
          onChange={(d) => {
            setDateFrom(dayjs(d).format("MM/DD/YYYY"));
          }}
          value={date_from !== "" ? dayjs(date_from) : null}
          maxDate={dayjs()}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "8px",
            },
            "& .MuiInputBase-input": {
              color: "#757575",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSvgIcon-root": {
              color: "#757575",
              marginRight: "8px",
            },
          }}
        />
      </LocalizationProvider>
      <p className="text-[#595959] text-sm font-ibmArabic my-2">To</p>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* Customized To Date Picker */}
        <DatePicker
          onChange={(d) => {
            setDateTo(dayjs(d).format("MM/DD/YYYY"));
          }}
          value={date_to !== "" ? dayjs(date_to) : null}
          minDate={date_to !== "" ? dayjs(date_to) : null}
          maxDate={dayjs()}
          sx={{
            width: "100%",

            "& .MuiOutlinedInput-root": {
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "8px 12px",
            },
            "& .MuiInputBase-input": {
              color: "#757575",
              fontSize: "14px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiSvgIcon-root": {
              color: "#757575",
              marginRight: "8px",
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default MoneyDateFilter;
