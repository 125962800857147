import React, { useCallback, useState } from "react";
import { Button } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DeliveryMainHeader from "../../components/DeliveryMainHeader";
import { imageBaseUrl } from "../../services/config";
import {
  useFetchSendOtp,
  useSendReceiveGoldOtp,
} from "../../services/apis/deliveryRequests";
import { setData } from "../../store/slices/errorMessageSlice";
import { useDispatch } from "react-redux";
import ReusableSnackbar from "../../components/ReusableSnackbar";

const VerifyId = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // Hook to manage URL query parameters
  const location = useLocation();
  const item = location.state;
  console.log("itemssss ", item?.national_front);
  const handleNavigation = (item) => {
    console.log("clicked ", item);
    navigate(`/NotReceivedRequest/${item.id}`, { state: item });
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const sendOtpMutation = useSendReceiveGoldOtp();
  const handleVerifyOtp = (item) => {
    console.log("clicked ", item);
    setIsSubmitting(true);

    sendOtpMutation.mutate(
      { id: item.id }, // Pass the item ID to the mutation
      {
        onSuccess: () => {
          setIsSubmitting(false);
          navigate(`/VerifyOtp/${item.id}`, { state: item });
        },
        onError: (error) => {
          setIsSubmitting(false);
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message || "Failed to get otp.",
            })
          );
        },
      }
    );
  };

  return (
    <div className="w-full h-full pb-[8%]">
      <DeliveryMainHeader
        label={"تحقق من الهوية"}
        back={true}
        onClick={() => navigate(-1)}
      />
      <main className="w-full" dir={"rtl"}>
        <div className="bg-red  flex items-center flex-col justify-center mt-6 ">
          <div className=" w-[90%]">
            <p className="text-[#404040] text-base font-normal font-ibmArabic mt-10">
              يرجى مطالبة العميل بعرض بطاقة الرقم القومي للتحقق من تطابقها مع
              المستند المرفوع خلال عملية التحقق من الهوية قبل المتابعة إلى
              الخطوة التالية.
            </p>
          </div>
          <div className="w-full flex items-center flex-col justify-center mt-10">
            <p className="text-[#B3B3B3] text-xs font-normal font-ibmArabic flex self-start ms-[6%] ">
              البطاقة
            </p>
            <div className="w-[90%] h-[147px] mt-3">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={`${imageBaseUrl}/${item?.national_front}`}
                alt=""
              />
            </div>
            <div className="w-[90%] h-[147px] mt-4">
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={`${imageBaseUrl}/${item?.national_back}`}
                alt=""
              />
            </div>
          </div>
          <div className="bg-red w-full flex items-center flex-col justify-center mt-6">
            <Button
              disabled={isSubmitting}
              onClick={() => {
                handleVerifyOtp(item);
              }}
              sx={{
                "&:hover": { backgroundColor: "#917244" },
                backgroundColor: "#917244",
                width: "90%",
                height: "48px",
                borderRadius: "12px",
                color: "#ffffff",
                fontSize: "16px",
                marginTop: "5%",
                fontFamily: "ibmArabic",
              }}
            >
              المتابعه
            </Button>
            <Button
              onClick={() => {
                handleNavigation(item);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
                backgroundColor: "#ffffff",
                width: "90%",
                height: "48px",
                borderRadius: "12px",
                color: "#917244",
                border: "2px solid #917244",
                fontSize: "16px",
                marginTop: "5%",
                fontFamily: "ibmArabic",
              }}
            >
              لم يتم التحقق
            </Button>
          </div>
        </div>
      </main>
      <ReusableSnackbar />
    </div>
  );
};

export default VerifyId;
