import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import DeliveryMainHeader from "../../components/DeliveryMainHeader";
import exports from "../../svg/exports.svg";
import { useImageStore } from "../../zustand/useImageStore";

const UploadNationalId = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  console.log("items 123 ", item);
  const {
    uploadedImages,
    setUploadedImage,
    nationalId,
    setNationalId,
    clearImages,
    clearNationalId,
  } = useImageStore();
  const isButtonDisabled =
    !nationalId ||
    nationalId.length !== 14 ||
    !/^\d{14}$/.test(nationalId) ||
    !uploadedImages.national_photo ||
    nationalId != item.nationalId;
  const [previewUrls, setPreviewUrls] = useState({
    national_photo: null,
  });
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

  const handleImageUpload = (e, key) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        console.log("Please upload a valid image file.");
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        console.log("File size should not exceed 10 MB.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(key, file); // Save the file
        setPreviewUrls((prev) => ({
          ...prev,
          [key]: URL.createObjectURL(file),
        }));
      };
      reader.readAsDataURL(file); // Read as base64 URL
    }
  };
  useEffect(() => {
    // Cleanup dynamically created URLs
    return () => {
      Object.values(previewUrls).forEach((url) => {
        if (url) URL.revokeObjectURL(url);
      });
    };
  }, [previewUrls]);
  const handleNavigation = (item) => {
    console.log("clicked ", item);
    navigate(`/UploadReceipt/${item.id}`, {
      state: { ...item, uploadedImages, nationalId },
    });
  };
  const goBackFunction = () => {
    clearImages();
    clearNationalId();
    navigate(-1);
  };
  return (
    <div className="w-full h-full pb-[8%]">
      <DeliveryMainHeader
        label={"إدخال الرقم القومي "}
        back={true}
        onClick={() => goBackFunction()}
      />
      <main className="w-full" dir={"rtl"}>
        <div className="bg-red  flex items-center flex-col justify-center mt-6 ">
          <div className=" w-[90%]">
            <p className="text-[#404040] text-base font-normal font-ibmArabic mt-10">
              يرجى إدخال الرقم القومي الخاص بالعميل لتأكيد هويته وإتمام عملية
              استلام الذهب.
            </p>
          </div>
          <div className="w-full flex items-center flex-col justify-center mt-10">
            <p className="text-[#595959] text-xs font-normal font-ibmArabic flex self-start ms-[6%] ">
              الرقم القومي
            </p>
            <input
              maxLength={14}
              type="text"
              placeholder="إدخال الرقم القومي"
              style={{
                fontSize: "16px",
                boxShadow:
                  "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
              }}
              className="w-[90%] h-12  rounded-lg px-4 mt-2 text-right text-black focus:outline-none focus:ring-2 focus:ring-[#917244]"
              value={nationalId}
              onChange={(e) =>
                setNationalId(e.target.value.replace(/[^0-9]/g, ""))
              }
            />
          </div>
          <div className="w-[90%] flex items-center flex-col justify-center mt-10 ">
            <p className="text-[#333333] text-base font-ibmArabic">
              يُرجى التقاط صور واضحة لكل من الجهة الأمامية والخلفية لبطاقة الرقم
              القومي. تأكد من وضوح جميع التفاصيل وأن الصور غير ضبابية أو محجوبة.
            </p>
            <div className="w-full mt-8">
              <p className="text-xs text-[#595959] font-normal font-ibmArabic">
                الجهة الأمامية لبطاقة الرقم القومي
              </p>
              <div className="border-2 border-dashed border-[#917244] rounded-xl p-8 py-12 flex flex-col items-center gap-4 mt-3 relative">
                {uploadedImages.national_photo ? (
                  <img
                    src={URL.createObjectURL(uploadedImages.national_photo)}
                    alt="national_photo"
                    className="w-full h-auto rounded-xl"
                  />
                ) : (
                  <>
                    <img src={exports} alt="export" width={24} height={24} />
                    <p className="text-[#404040] text-xs font-normal font-ibmArabic">
                      الأمامية لبطاقة الهوية
                    </p>
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  capture="environment"
                  onChange={(e) => handleImageUpload(e, "national_photo")}
                />
              </div>
            </div>
          </div>
          <div className="bg-red w-full flex items-center flex-col justify-center mt-6">
            <Button
              disabled={isButtonDisabled}
              onClick={() => {
                handleNavigation(item);
              }}
              sx={{
                "&:hover": { backgroundColor: "#917244" },
                backgroundColor: "#917244",
                width: "90%",
                height: "48px",
                borderRadius: "12px",
                color: "#ffffff",
                fontSize: "16px",
                marginTop: "5%",
                fontFamily: "ibmArabic",
              }}
            >
              تأكيد الرقم القومي
            </Button>
            <Button
              onClick={() => {
                goBackFunction();
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
                backgroundColor: "#ffffff",
                width: "90%",
                height: "48px",
                borderRadius: "12px",
                color: "#917244",
                border: "2px solid #917244",
                fontSize: "16px",
                marginTop: "5%",
                fontFamily: "ibmArabic",
              }}
            >
              رجوع
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UploadNationalId;
