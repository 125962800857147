import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NewUserInfoField from "../../user-profile/NewUserInfoField";
import { formDate } from "../../../services/helpers";
import { Box, Button, Skeleton } from "@mui/material";
import useWindowSize from "../../../hooks/useWindowSize";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NewTable from "../../../components/common/NewTable";
import { ItemsColumns } from "../../../components/common/Columns";
import PreparingDialog from "./PreparingDialog";
import CancelRequestDialog from "./CancelRequestDialog";
import { useFetchDetailsReceiveRequests } from "../../../services/apis/ReceiveGold";
import PickupDialog from "./PickupDialog";

const AccordionStyle = {
  "&:before": {
    backgroundColor: "transparent !important",
    borderRadius: "24px !important",
  },
  boxShadow: `0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012`,
  borderRadius: "24px !important",
  marginTop: "32px",
  overflow: "hidden", // Ensures child elements respect border-radius
};
const ReceiveRequestsDetails = () => {
  const [DialogOpen, setDialogOpen] = useState(false);
  const [pickupDialogOpen, setPickupDialogOpen] = useState(false);
  const [CancelDialogOpen, setCancelDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleDialogPickupClose = () => {
    setPickupDialogOpen(false);
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: DetailsData,
    isFetching,
    refetch,
  } = useFetchDetailsReceiveRequests(id);
  console.log("details ", DetailsData);
  const { width } = useWindowSize();
  const newColumns = ItemsColumns([
    {
      field: "weight",
      headerName: "Weight",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        return <p>{Number(params?.row?.weight)?.toLocaleString()} Gram</p>;
      },
    },
    {
      field: "serials",
      headerName: "Serial number",
      flex: 2,
    },
  ]);
  const statusMapping = {
    1: { text: "New Request", color: "#917244" },
    2: { text: "Preparing for Pickup", color: "#FFC107" },
    3: { text: "Ready for Pickup", color: "#17A2B8" },
    4: { text: "Received", color: "#28A745" },
    5: { text: "Canceled", color: "#DC3545" },
    6: { text: "Pickup Missed", color: "#DD670D" },
    7: { text: "Ready for Pickup", color: "#17A2B8" },
  };
  const handleClick = () => {
    navigate(`/users/${DetailsData?.user_id}`);
  };
  return (
    <>
      <div className="mx-6 font-ibmArabic mb-20">
        <h1 className="text-sm text-[#595959] mb-12 flex flex-row items-center">
          <span
            className="cursor-pointer text-[#917244] hover:text-[#917244] mr-1"
            onClick={() => {
              navigate(-1);
            }}
          >
            Receive Requests
          </span>{" "}
          {`>`}{" "}
          <span className="text-[#404040] ml-1">
            {" "}
            {isFetching ? <Skeleton width={"30px"} /> : DetailsData?.id}
          </span>
        </h1>

        <div
          className="p-12 rounded-3xl "
          style={{
            boxShadow: `0px 1px 3px 0px #00000005,0px 1px 6px 0px #00000012`,
          }}
        >
          <div className="flex items-center justify-between mb-8">
            <div className="flex flex-col w-[60%]">
              <p className="text-[#333333] font-medium text-2xl">
                Gold Pickup Request Details
              </p>
              <p className="text-[#333333] font-normal text-sm   mt-2">
                Track and manage gold withdrawal requests, including status
                updates, assigned gold details, and final verification before
                user pickup.
              </p>
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={() => {
                  //   navigate(`/transactions/${id}`);
                }}
                className="text-[#E9C237] font-medium text-sm bg-[#FBF5DE] px-6 py-3 rounded-xl"
              >
                User Gold Transactions
              </button>
            </div>
          </div>
          <div className="grid">
            <div className="grid grid-cols-2 py-3">
              <NewUserInfoField
                title={"Request Id"}
                data={
                  isFetching ? <Skeleton width={"100px"} /> : DetailsData?.id
                }
              />
              <NewUserInfoField
                title={"Request Date"}
                data={
                  isFetching ? (
                    <Skeleton width={"100px"} />
                  ) : (
                    formDate(DetailsData?.created)
                  )
                }
              />
            </div>
            <hr className="my-6" />
            <div className="grid grid-cols-2 py-3">
              <NewUserInfoField
                title={"Request Status"}
                field={
                  isFetching ? (
                    <Skeleton width={"100px"} />
                  ) : (
                    <div
                      className={`border-l-2 px-2 text-lg`}
                      style={{
                        borderColor:
                          statusMapping[DetailsData?.status]?.color || "#000",
                        color:
                          statusMapping[DetailsData?.status]?.color || "#000",
                      }}
                    >
                      {statusMapping[DetailsData?.status]?.text ||
                        "Unknown Status"}
                    </div>
                  )
                }
              />
              <NewUserInfoField
                title={"Request type"}
                data={
                  isFetching ? <Skeleton width={"100px"} /> : DetailsData?.type
                }
              />
            </div>
            <hr className="my-6" />
            {DetailsData?.status === "3" && (
              <>
                <div className="grid grid-cols-1 py-3">
                  <NewUserInfoField
                    title={"Pickup Address"}
                    data={
                      isFetching ? (
                        <Skeleton width={"100px"} />
                      ) : (
                        DetailsData?.receive_location
                      )
                    }
                  />
                </div>
                <hr className="my-6" />
              </>
            )}
            {DetailsData?.receive_time && (
              <>
                <div className="grid grid-cols-1 py-3">
                  <NewUserInfoField
                    title={"Pickup Date"}
                    data={
                      isFetching ? (
                        <Skeleton width={"100px"} />
                      ) : (
                        formDate(DetailsData?.receive_time)
                      )
                    }
                  />
                </div>
                <hr className="my-6" />
              </>
            )}
            <div className="grid grid-cols-2 py-3">
              <NewUserInfoField
                title={"Ingot Weight"}
                data={
                  isFetching ? (
                    <Skeleton width={"100px"} />
                  ) : (
                    Number(DetailsData?.weight) + " Gram"
                  )
                }
              />
              <NewUserInfoField
                title={"Ingots amount"}
                data={
                  isFetching ? (
                    <Skeleton width={"100px"} />
                  ) : (
                    DetailsData?.ingot_count
                  )
                }
              />
            </div>
            <hr className="my-6" />
            <div className="grid grid-cols-2 py-3">
              <NewUserInfoField
                title={"Total Weight"}
                data={
                  isFetching ? (
                    <Skeleton width={"100px"} />
                  ) : (
                    DetailsData?.ingot_count * Number(DetailsData?.weight) +
                    " Gram"
                  )
                }
              />
              {DetailsData?.modified && (
                <NewUserInfoField
                  title={"Last Update"}
                  data={
                    isFetching ? (
                      <Skeleton width={"100px"} />
                    ) : (
                      formDate(DetailsData?.modified)
                    )
                  }
                />
              )}
            </div>
            <hr className="my-6" />
            {DetailsData?.summary?.ingot_serials?.length > 0 && (
              <div className="flex flex-wrap gap-[4px] mt-2">
                <NewUserInfoField
                  title="Ingot Serial Number"
                  data={
                    isFetching ? (
                      <Skeleton width={"100px"} />
                    ) : (
                      <div className="flex flex-wrap gap-[4px] mt-2">
                        {DetailsData?.summary?.ingot_serials?.map(
                          (item, index) => (
                            <div
                              key={index}
                              className="m-[2px] rounded-xl bg-[#F5F5F5] px-[12px] py-[12px]"
                            >
                              <h1 className="text-[#333333] text-sm font-normal">
                                {item}
                              </h1>
                            </div>
                          )
                        )}
                      </div>
                    )
                  }
                />
              </div>
            )}
          </div>

          <div className="grid grid-cols-1  items-center justify-end md:flex md:self-end md:gap-4">
            {DetailsData?.status !== "5" &&
              DetailsData?.status === "1" &&
              !isFetching && (
                <Button
                  onClick={() => setDialogOpen(true)}
                  sx={{
                    "&:hover": { backgroundColor: "#917244" },
                    backgroundColor: "#917244",
                    width: width > 768 ? "200px" : "90%",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#ffffff",
                    fontSize: "14px",
                    marginTop: "5%",
                  }}
                >
                  Mark as Preparing
                </Button>
              )}
            {DetailsData?.status !== "5" &&
              DetailsData?.status === "2" &&
              !isFetching && (
                <Button
                  onClick={() => setPickupDialogOpen(true)}
                  sx={{
                    "&:hover": { backgroundColor: "#917244" },
                    backgroundColor: "#917244",
                    width: width > 768 ? "200px" : "90%",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#ffffff",
                    fontSize: "14px",
                    marginTop: "5%",
                  }}
                >
                  Mark as Ready for Pickup
                </Button>
              )}
            {DetailsData?.status !== "5" &&
              DetailsData?.status === "6" &&
              !isFetching && (
                <Button
                  onClick={() => setPickupDialogOpen(true)}
                  sx={{
                    "&:hover": { backgroundColor: "#917244" },
                    backgroundColor: "#917244",
                    width: width > 768 ? "200px" : "90%",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#ffffff",
                    fontSize: "14px",
                    marginTop: "5%",
                  }}
                >
                  Reschedule
                </Button>
              )}
            {DetailsData?.status !== "5" &&
              DetailsData?.status !== "4" &&
              !isFetching && (
                <Button
                  onClick={() => setCancelDialogOpen(true)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                    backgroundColor: "#ffffff",
                    width: width > 768 ? "200px" : "90%",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#DC3545",
                    border: "2px solid #DC3545",
                    fontSize: "14px",
                    marginTop: "5%",
                  }}
                >
                  Cancel Request
                </Button>
              )}
          </div>

          <div className="flex flex-col mt-8">
            <p className="text-[#000000] font-normal text-lg">
              Note for Admin:
            </p>
            <p className="text-[#000000] font-normal text-sm w-[60%] mt-2">
              The gold has been assigned and is ready for collection. Ensure all
              necessary verifications are completed before confirming the
              handover to the user.
            </p>
          </div>
        </div>
        <Accordion sx={AccordionStyle}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "500",
                borderTop: "0px solid transparent",
                paddingBottom: "12px",
                width: "60%",
              }}
            >
              <Typography
                sx={{ color: "#333333", fontSize: "24px", fontWeight: "400" }}
              >
                User Details
              </Typography>
              <Typography
                sx={{
                  color: "#333333",
                  fontSize: "14px",
                }}
              >
                View and verify user information related to gold receive
                requests.
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div className="grid">
              <div className="grid grid-cols-2 py-3">
                <NewUserInfoField
                  title={"User name"}
                  field={
                    isFetching ? (
                      <Skeleton width={"100px"} />
                    ) : (
                      <div
                        onClick={handleClick}
                        className={`
                    text-lg
                   text-[#917244] underline cursor-pointer
                     `}
                      >
                        {DetailsData?.firstName + " " + DetailsData?.lastName}
                      </div>
                    )
                  }
                />
                <NewUserInfoField
                  title={"Phone number"}
                  data={
                    isFetching ? (
                      <Skeleton width={"100px"} />
                    ) : (
                      "0" + DetailsData?.phone
                    )
                  }
                />
              </div>
              <hr className="my-6" />
              <div className="grid grid-cols-2 py-3">
                <NewUserInfoField
                  title={"National ID"}
                  data={
                    isFetching ? (
                      <Skeleton width={"100px"} />
                    ) : (
                      DetailsData?.nationalId
                    )
                  }
                />
                <NewUserInfoField
                  title={"National ID Name "}
                  data={
                    isFetching ? (
                      <Skeleton width={"100px"} />
                    ) : (
                      DetailsData?.national_name
                    )
                  }
                />
              </div>
              <hr className="my-6" />
              <div className="grid grid-cols-2 py-3">
                <NewUserInfoField
                  title={"Email"}
                  data={
                    isFetching ? (
                      <Skeleton width={"100px"} />
                    ) : (
                      DetailsData?.email
                    )
                  }
                />
              </div>
              <hr className="my-6" />
              <div className="grid grid-cols-2 py-3">
                <NewUserInfoField
                  title={"Total Owned Fractions"}
                  data={
                    isFetching ? (
                      <Skeleton width={"100px"} />
                    ) : (
                      DetailsData?.userGold + " Gram"
                    )
                  }
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {(DetailsData?.status === "3" ||
          DetailsData?.status === "4" ||
          DetailsData?.status === "7") && (
          <Accordion sx={AccordionStyle}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: "500",
                  borderTop: "0px solid transparent", // Remove top border
                  paddingBottom: "12px",
                  width: "60%",
                }}
              >
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "24px",
                    fontWeight: "400",
                  }}
                >
                  Payment & Fees Summary
                </Typography>
                <Typography
                  sx={{
                    color: "#333333",
                    fontSize: "14px",
                  }}
                >
                  This section provides a comprehensive breakdown of the
                  financial details related to the user's gold transaction,
                  including payments, acquisition costs, provider expenses, and
                  net financial outcome.
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <div className="grid">
                <div className="grid grid-cols-2 py-3">
                  <NewUserInfoField
                    title={"Amount Paid by User"}
                    data={
                      isFetching ? (
                        <Skeleton width={"100px"} />
                      ) : (
                        DetailsData?.summary?.amount_payedByUser + " EGP "
                      )
                    }
                  />
                  <NewUserInfoField
                    title={"Amount Paid to Provider (Gold Cost)"}
                    data={
                      isFetching ? (
                        <Skeleton width={"100px"} />
                      ) : (
                        DetailsData?.summary?.amount_payedToProvider + " EGP"
                      )
                    }
                  />
                </div>
                <hr className="my-6" />
                <div className="grid grid-cols-2 py-3">
                  <NewUserInfoField
                    title={"Manufacturing Fee Paid by User"}
                    data={
                      isFetching ? (
                        <Skeleton width={"100px"} />
                      ) : (
                        DetailsData?.summary?.manufacturing_byUser + " EGP"
                      )
                    }
                  />
                  <NewUserInfoField
                    title={"Manufacturing Fee Paid to Provider"}
                    data={
                      isFetching ? (
                        <Skeleton width={"100px"} />
                      ) : (
                        DetailsData?.summary?.manufacturing_toProvider + " EGP"
                      )
                    }
                  />
                </div>
                <hr className="my-6" />
                <div className="grid grid-cols-2 py-3">
                  <NewUserInfoField
                    title={"Storage Fee"}
                    data={
                      isFetching ? (
                        <Skeleton width={"100px"} />
                      ) : (
                        DetailsData?.summary?.store_fees + " EGP"
                      )
                    }
                  />
                  <NewUserInfoField
                    title={"Total gains/losses"}
                    field={
                      isFetching ? (
                        <Skeleton width={"100px"} />
                      ) : (
                        <div
                          className={`
                      text-lg
                     text-[#DC3545]
                       `}
                        >
                          {DetailsData?.summary?.total_gain + " EGP"}
                        </div>
                      )
                    }
                  />
                </div>
                <hr className="my-6" />
                <div className="grid grid-cols-2 py-3">
                  <NewUserInfoField
                    title={"Fee for Gold Processing"}
                    data={
                      isFetching ? (
                        <Skeleton width={"100px"} />
                      ) : (
                        DetailsData?.summary?.request_fees + " EGP"
                      )
                    }
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion sx={AccordionStyle}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                fontWeight: "500",
                borderTop: "0px solid transparent", // Remove top border
                paddingBottom: "12px",
                width: "60%",
              }}
            >
              <Typography
                sx={{ color: "#333333", fontSize: "24px", fontWeight: "400" }}
              >
                Serial Number Records
              </Typography>
              <Typography sx={{ color: "#333333", fontSize: "14px" }}>
                View and track the serial numbers along with their associated
                gold weights. These serials will be released once the user
                successfully receives their gold.
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div className="">
              <NewTable
                show={true}
                centerHeader
                columns={newColumns}
                data={DetailsData?.serials}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <PreparingDialog
          requestId={id}
          handleDialogClose={handleDialogClose}
          DialogOpen={DialogOpen}
          setDialogOpen={setDialogOpen}
          refetch={refetch}
        />
        <PickupDialog
          DetailsData={DetailsData}
          requestId={id}
          handleDialogPickupClose={handleDialogPickupClose}
          pickupDialogOpen={pickupDialogOpen}
          setPickupDialogOpen={setPickupDialogOpen}
          refetch={refetch}
        />
        <CancelRequestDialog
          refetch={refetch}
          requestId={id}
          CancelDialogOpen={CancelDialogOpen}
          setCancelDialogOpen={setCancelDialogOpen}
        />
      </div>
    </>
  );
};

export default ReceiveRequestsDetails;
