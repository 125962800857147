import React, { useCallback, useState } from "react";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "../../services/helpers";
import DeliveryMainHeader from "../../components/DeliveryMainHeader";
import { useMissedAgentRequests } from "../../services/apis/deliveryRequests";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";

const NotReceivedRequest = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const mutation = useMissedAgentRequests();
  const [cancelReason, setCancelReason] = useState("");
  const dispatch = useDispatch();
  const handleMissedRequest = async () => {
    setIsSubmitting(true);

    mutation.mutate(
      { id: item?.id, body: { reason: cancelReason } },
      {
        onSuccess: () => {
          setIsSubmitting(false);
          navigate(`/MissedSuccessPage`);
        },
        onError: (error) => {
          setIsSubmitting(false);
          dispatch(
            setData({
              openSnack: true,
              message:
                error.response.data.message || "Failed to cancel the request.",
            })
          );
        },
      }
    );
  };
  return (
    <div className="w-full h-full pb-[8%]">
      <DeliveryMainHeader
        // label={"سبب عدم تسليم الذهب"}
        back={true}
        onClick={() => navigate(-1)}
      />
      <main className="w-full" dir={"rtl"}>
        <div className="bg-red  flex items-center flex-col justify-center mt-6 ">
          <div className=" w-[90%]">
            <h1 className="text-[#333333] font-medium text-2xl font-ibmArabic">
              سبب عدم تسليم الذهب
            </h1>
            <h1 className="text-[#404040] font-normal text-base font-ibmArabic mt-3">
              يرجى تقديم سبب المشكلة. تأكد من إضافة أي معلومات ذات صلة تساعد في
              حل المشكلة بسرعة.
            </h1>
            <p className="text-[#595959] text-sm font-normal font-ibmArabic mt-10">
              السبب
            </p>
            <textarea
              className="w-full mt-3 p-3 rounded-xl text-right text-[#333] focus:outline-none focus:ring-2 focus:ring-[#917244]"
              placeholder="يرجي إدخال سبب عدم التسليم"
              rows="5"
              value={cancelReason}
              minLength={10}
              maxLength={200} //
              onChange={(event) => setCancelReason(event.target.value)}
              style={{
                fontSize: "16px",
                minHeight: "100px", // Minimum height
                maxHeight: "300px", // Maximum height
                minWidth: "100%", // Minimum width (full width)
                maxWidth: "100%", // Maximum width (full width)
                resize: "vertical", // Allow vertical resizing only
                boxShadow:
                  "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
              }}
            ></textarea>
          </div>
          <div className="w-full flex items-center justify-center flex-col mt-6">
            <Button
              disabled={isSubmitting || !cancelReason.trim()}
              onClick={() => {
                handleMissedRequest();
              }}
              sx={{
                "&:hover": { backgroundColor: "#917244" },
                backgroundColor: "#917244",
                width: "90%",
                height: "48px",
                borderRadius: "16px",
                color: "#ffffff",
                fontSize: "14px",
                fontFamily: "ibmArabic",
              }}
            >
              إرسال
            </Button>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
                backgroundColor: "#ffffff",
                width: "90%",
                height: "48px",
                borderRadius: "12px",
                color: "#917244",
                border: "2px solid #917244",
                fontSize: "16px",
                marginTop: "5%",
                fontFamily: "ibmArabic",
              }}
            >
              رجوع
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotReceivedRequest;
