import { useMutation, useQuery } from "react-query";
import { axiosInstance as axios } from "../config";

export const fetchAllReceiveRequests = async (q) => {
  try {
    const response = await axios.get(`/api/receiveGold/index`, {
      params: {
        page: q.page,
        limit: q.limit,
        query: q.query.startsWith("0") ? q.query.slice(1) : q.query,
      },
    });
    return response.data.data; // Assuming you want to return the data part of the response
  } catch (error) {
    throw error;
  }
};

export const useFetchAllReceiveRequests = (q) => {
  return useQuery(
    ["fetchAllReceiveRequests", q],
    () => fetchAllReceiveRequests(q),
    {
      enabled: !!q,
    }
  );
};
export const fetchDetailsReceiveRequests = async (id) => {
  try {
    const response = await axios.get(`/api/receiveGold/${id}`);
    return response.data.data; // Assuming you want to return the data part of the response
  } catch (error) {
    throw error;
  }
};

export const useFetchDetailsReceiveRequests = (id) => {
  return useQuery(
    ["fetchDetailsReceiveRequests", id],
    () => fetchDetailsReceiveRequests(id),
    {
      enabled: !!id,
    }
  );
};
const cancelDetailsReceiveRequests = async ({ id, body }) => {
  try {
    const response = await axios.put(`/api/receiveGold/cancel/${id}`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const useCancelDetailsReceiveRequests = () => {
  return useMutation(cancelDetailsReceiveRequests);
};
const updateDetailsReceiveRequests = async ({ id, body }) => {
  try {
    const response = await axios.put(`/api/receiveGold/update/${id}`, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const useUpdateDetailsReceiveRequests = () => {
  return useMutation(updateDetailsReceiveRequests);
};

export const fetchSerialsByWeight = async (id) => {
  try {
    const response = await axios.get(`/api/inventory/getSerialsByWeight/${id}`);
    return response.data.data; // Assuming you want to return the data part of the response
  } catch (error) {
    throw error;
  }
};

export const useFetchSerialsByWeight = (id) => {
  return useQuery(
    ["fetchSerialsByWeight", id],
    () => fetchSerialsByWeight(id),
    {
      enabled: !!id,
    }
  );
};
