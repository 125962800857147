import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Header = ({
  title,
  subtitle,
  marginL = 0,
  marginB = 0,
  marginT = 0,
  marginR = 0,
  sx = {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box ml={marginL} mr={marginR} mb={marginB} mt={marginT} sx={sx}>
      <Typography
        variant="h2"
        color={colors.grey[100]}
        sx={{
          m: "0 0 5px 0",
          fontSize: "28px",
          fontWeight: 500,
          color: "#404040",
        }}
      >
        {title}
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
