import { useMutation } from "react-query";
import { axiosInstance as axios } from "../config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setToken } from "../../store/slices/authenticationSlice";
const login = async (email, password) => {
  try {
    const body = { email, password };
    const response = await axios.post(`/api/admin/login`, body);
    const { data } = response;
    const { accessToken } = response.data.data;
    localStorage.setItem("token", accessToken);
    return data;
  } catch (error) {
    // Instead of returning the error directly, throw it
    throw error;
  }
};

// hooks/useAuth.js
export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const queryClient = useQueryClient();

  return useMutation(({ email, password }) => login(email, password), {
    onSuccess: (data) => {
      dispatch(setToken(data?.data?.accessToken));
      navigate("/AdminProfile", { replace: true });
    },
    onError: (error, variables) => {
      console.log("Error response:", error?.response?.data);

      if (error?.response?.data?.message === "your_email_is_not_verified") {
        console.log("Email not verified:", variables.email);
        navigate("/ChangePassword", { state: { email: variables.email } });
      } else {
        console.log("Other error:", error.message);
        // navigate("/ChangePassword", { state: { errorMessage: error.message } });
      }
    },
  });
};
