import { Box, Button, Typography } from "@mui/material";
import { useLogin } from "../../../services/apis/auth";
import { useForm } from "react-hook-form";
import RHFTextField from "../../../components/RHF/RHFTextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import login from "../../../svg/login.svg";
import ButtonLoader from "../../../components/ButtonLoader";

const schema = yup.object({
  NewPassword: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  ConfirmNewPassword: yup
    .string()
    .oneOf([yup.ref("NewPassword")], "Passwords must match")
    .required("Confirm password is required"),
});

const ChangePassword = () => {
  const location = useLocation();
  const email = location.state?.email || "";
  const dispatch = useDispatch();

  const { mutate: ChangePassword, error, isLoading } = useLogin();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { NewPassword: "", ConfirmNewPassword: "", email: email },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const onSubmit = async ({ NewPassword, ConfirmNewPassword, email }) => {
    console.log("Form submitted");
    console.log("data payload ", NewPassword, ConfirmNewPassword, email);
    // ChangePassword({ NewPassword, ConfirmNewPassword, email });
  };
  useEffect(() => {
    if (error) {
      if (error?.response?.data?.redirction) {
        navigate(`/${error?.response?.data?.redirction}`);
      }
      dispatch(
        setData({ message: "Invalid Email or Password", openSnack: true })
      );
    } else {
      dispatch(setData({ openSnack: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div className=" h-screen">
      <div className="h-full flex flex-col md:flex-row ">
        {/* form */}
        <div className="flex flex-col h-full bg-white items-center justify-center w-full md:w-1/2 p-2">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex  flex-col w-11/12 md:max-w-md lg:max-w-lg xl:max-w-xl"
            autoComplete="off"
            noValidate
          >
            <div className="md:text-3xl lg:text-3xl text-3xl mt-6 mb-4 text-[#4C4C4C] font-medium">
              Set Your New Password
            </div>
            <div className="md:text-xl lg:text-2xl text-base  mb-4 text-[#4C4C4C] font-medium">
              For security reasons, please set a new password for your account.
              Choose a strong password to protect your information.
            </div>
            <Box
              className="flex flex-col gap-4"
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <RHFTextField
                sx={{
                  "& fieldset": !errors?.NewPassword ? { border: "none" } : "",
                  backgroundColor: errors?.NewPassword ? "#FFF" : "#F7F7F7",
                  borderRadius: "9px",
                }}
                name={"NewPassword"}
                control={control}
                label="Enter New Password"
                errors={errors?.NewPassword}
                type="password"
              />
              <RHFTextField
                sx={{
                  "& fieldset": !errors?.ConfirmNewPassword
                    ? { border: "none" }
                    : "",
                  backgroundColor: errors?.ConfirmNewPassword
                    ? "#FFF"
                    : "#F7F7F7",
                  borderRadius: "9px",
                }}
                name={"ConfirmNewPassword"}
                control={control}
                label="Confirm New Password"
                errors={errors?.ConfirmNewPassword}
                type="password"
              />
            </Box>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#917244",
                "&:hover": { backgroundColor: "#917244" },
                height: "48px",
                marginTop: "40px",
                borderRadius: "12px",
              }}
            >
              {isLoading ? (
                <ButtonLoader color="inherit" size={20} />
              ) : (
                "Save and Continue "
              )}
            </Button>
          </form>
        </div>
        {/* image */}
        <div className="hidden md:flex lg:flex h-full bg-[#F7F7F7] items-center justify-center w-full  md:w-1/2 lg:w-1/2">
          <div className="w-[450px] h-[450px]">
            <img src={login} alt="login" className="h-full w-full" />
          </div>
        </div>
      </div>
      <ReusableSnackbar />
    </div>
  );
};

export default ChangePassword;
