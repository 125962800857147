import { Box, Typography } from "@mui/material";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { useFetchSellFractionsIngot } from "../../services/apis/inventory";
import { useEffect, useState } from "react";
import MergeFractionDialog from "./MergeFractionDialog";
import SellFractionDialog from "./SellFractionDialog";
import { ItemsColumns } from "../../components/common/Columns";
import NewTabs from "../../components/common/NewTabs";
import NewTable from "../../components/common/NewTable";
import { useSearchParams } from "react-router-dom";
const SellFractionsIngot = () => {
  const tabs = [
    { label: "Merge Ingots", value: "1" },
    { label: "Sell Ingots", value: "2" },
  ];
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("tab") || "1";
  const [value, setValue] = useState(initialTab);
  const [openMergeDialog, setOpenMergeDialog] = useState(false);
  const [openSellDialog, setOpenSellDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    limit: 100000,
  });
  const {
    data: sellFractionsIngot,
    isFetching,
    refetch,
  } = useFetchSellFractionsIngot(queryParameters);
  const [mergeIngotsData, setMergeIngotsData] = useState([]);
  const [sellIngotsData, setSellIngotsData] = useState([]);
  useEffect(() => {
    setValue(initialTab);
  }, [initialTab]);
  useEffect(() => {
    if (sellFractionsColumns) {
      setMergeIngotsData(
        sellFractionsIngot?.data?.data?.result?.filter(
          (item) => parseFloat(item.soldWeight) > 0
        )
      );
      setSellIngotsData(
        sellFractionsIngot?.data?.data?.result?.filter(
          (item) => parseFloat(item.soldWeight) === 0 || !item.soldWeight
        )
      );
    }
  }, [isFetching]);
  const handleChange = (newValue) => {
    refetch();
    setValue(newValue);
    setSearchParams({ tab: newValue });
  };
  const handleCloseMergeDialog = () => {
    setOpenMergeDialog(false);
  };

  const handleCloseSellDialog = () => {
    setOpenSellDialog(false);
  };

  const handleOpenMergeDialog = (row) => {
    setSelectedRow(row);
    setOpenMergeDialog(true);
  };

  const handleOpenSellDialog = (row) => {
    setSelectedRow(row);
    setOpenSellDialog(true);
  };

  const handleActionClick = (row) => {
    if (row?.soldWeight > 0) {
      handleOpenMergeDialog(row);
    } else {
      handleOpenSellDialog(row);
    }
  };
  const sellFractionsColumns = ItemsColumns([
    { field: "name", headerName: "Product" },
    { field: "serial", headerName: "Serial Number" },
    {
      field: "soldWeight",
      headerName: "Sold Weight",
      specialRender: "customRender",
      renderFunction: (params) => {
        const soldWeight = params.row.soldWeight ?? 0;
        return (
          <Typography sx={{ color: "#FF453A", fontSize: 14 }}>{`${parseFloat(
            soldWeight
          )} Gm`}</Typography>
        );
      },
    },
    {
      field: "currentWeight",
      headerName: "Available Weight",
      specialRender: "customRender",
      renderFunction: (params) => {
        const currentWeight = params.row.currentWeight ?? 0;
        return (
          <Typography sx={{ color: "#30D158", fontSize: 14 }}>{`${parseFloat(
            currentWeight
          )} Gm`}</Typography>
        );
      },
    },
    {
      field: "name",
      headerName: "Action",
      specialRender: "customRender",
      renderFunction: (params) => (
        <button
          className="bg-[#917244] text-white px-4 py-2 rounded-2xl w-full text-center"
          onClick={() => {
            handleActionClick(params.row);
          }}
        >
          {parseFloat(params.row.soldWeight) > 0 ? "Merge" : "Sell"}
        </button>
      ),
    },
  ]);

  return (
    <Box>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Sell Ingots" marginL={"25px"} />
      <div className="m-6 rounded-xl p-6 bg-white">
        <NewTabs
          tabs={tabs}
          value={value}
          onChange={handleChange}
          className={"mb-6"}
        />
        {value === "1" ? (
          <NewTable
            columns={sellFractionsColumns}
            isLoading={isFetching}
            data={mergeIngotsData}
            onCellClick={(params) => {
              handleActionClick(params.row);
            }}
            noPagination={true}
            // totalPages={sellFractionsIngot?.data?.data?.totalPages}
            // totalItems={sellFractionsIngot?.data?.data?.totalItems}
            // handlePageChange={(newPage) =>
            //   handlePageChange(newPage, queryParameters, setQueryParameters)
            // }
            // currentPage={queryParameters}
          />
        ) : (
          <NewTable
            columns={sellFractionsColumns}
            isLoading={isFetching}
            data={sellIngotsData}
            noPagination={true}
            // totalPages={sellFractionsIngot?.data?.data?.totalPages}
            // totalItems={sellFractionsIngot?.data?.data?.totalItems}
            // handlePageChange={(newPage) =>
            //   handlePageChange(newPage, queryParameters, setQueryParameters)
            // }
            // currentPage={queryParameters}
            onCellClick={(params) => {
              handleActionClick(params.row);
            }}
          />
        )}
        {selectedRow && (
          <>
            <MergeFractionDialog
              open={openMergeDialog}
              onClose={handleCloseMergeDialog}
              label={"Confirm"}
              row={selectedRow}
              refetch={refetch}
              data={sellFractionsIngot?.data?.data}
            />
            <SellFractionDialog
              open={openSellDialog}
              onClose={handleCloseSellDialog}
              label={"Sell"}
              refetch={refetch}
              row={selectedRow}
            />
          </>
        )}
      </div>
    </Box>
  );
};

export default SellFractionsIngot;
