import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Users from "./scenes/users";
import RegistrationForm from "./scenes/auth/register";
import Transactions from "./scenes/transactions";
import GoldTransactions from "./scenes/gold-transactions";
import ANR from "./scenes/add-new-role";
import AddNewUser from "./scenes/add-new-user";
import Bar from "./scenes/bar";
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import TransactionsProvider from "./contexts/TransactionsContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import UserProfile from "./scenes/user-profile";
import { useEffect } from "react";
import AppManagement from "./scenes/app-content-management";
import Notifications from "./scenes/notifications";
import Orders from "./scenes/orders";
import Terms from "./scenes/terms";
import CitiesAndTowns from "./scenes/cities-and-towns";
import PaymentInfo from "./scenes/paymentInfo";
import AddNewProduct from "./scenes/add-new-product";
import ForgetPassword from "./scenes/auth/ForgetPassword";
import ResetPassword from "./scenes/auth/ResetPassword";
import ViewRole from "./scenes/add-new-role/ViewRole";
import ViewAdminProfile from "./scenes/add-new-role/ViewAdminProfile";
import AddNewRole from "./scenes/add-new-role/AddNewRole";
import RolesCategories from "./scenes/permissions/RolesCategories";
import ResourcePermissions from "./scenes/permissions/ResourcePermissions";
import RolePermissions from "./scenes/permissions/RolePermissions";
import UserPermissions from "./scenes/permissions/UserPermissions";
import AdminProfile from "./scenes/AdminProfile";
import DeletedUsers from "./scenes/DeletedUsers";
import DeactivatedAdmin from "./scenes/deactivatedAdmin";
import Configuration from "./scenes/configuration";
import FractionsTransfer from "./scenes/fractionsTransfer";
import Requests from "./scenes/Inventory/requests";
import OrderDetails from "./scenes/Inventory/requests/OrderDetails";
import Stock from "./scenes/Inventory/stock";
import StockDetails from "./scenes/Inventory/stock/StockDetails";
import AddNewFAQ from "./scenes/faq/AddNewFAQ";
import PendingOrders from "./scenes/orders/pendingOrders";
import PendingOrdersDetails from "./scenes/orders/pendingOrders/PendingOrdersDetails";
import Banks from "./scenes/banks";
import Tickets from "./scenes/tickets";
import TicketsDetails from "./scenes/tickets/ticketsDetails";
import AddNewTerms from "./scenes/terms/AddNewTerms";
import AddNewNotification from "./scenes/notifications/AddNewNotification";
import NotificationDetails from "./scenes/notifications/NotificationDetails";
import TicketSubjects from "./scenes/ticketSubjects";
import TicketQuestions from "./scenes/ticketQuestions";
import AddNewTicketQuestion from "./scenes/ticketQuestions/AddNewTicketQuestion";
import BlockReasons from "./scenes/blockReasons/blockReasons";
import AddNewReason from "./scenes/blockReasons/AddNewReason";
import ClosedReasons from "./scenes/closedReasons";
import AddClosedReason from "./scenes/closedReasons/AddClosedReason";
import SubQuestionsDetails from "./scenes/ticketQuestions/SubQuestionsDetails";
import AddNewSubQuestion from "./scenes/ticketQuestions/AddNewSubQuestion";
import EditSubQuestion from "./scenes/ticketQuestions/EditSubQuestion";
import EditTicketQuestion from "./scenes/ticketQuestions/EditTicketQuestion";
import { useDispatch, useSelector } from "react-redux";
import NewLogin from "./scenes/auth/login/NewLogin";
import SellFractionsIngot from "./scenes/sellFractionsIngot";
import Articles from "./scenes/articles";
import About from "./scenes/about";
import AddArticle from "./scenes/articles/AddArticle";
import EditArticle from "./scenes/articles/EditArticle";
import SoldIngots from "./scenes/Inventory/soldIngots/Index";
import ProductDetailsPage from "./scenes/product-details";
import AppBadge from "./scenes/app-badge/AppBadge";
import AddBadge from "./scenes/app-badge/AddBadge";
import AppBadgeDetails from "./scenes/app-badge/AppBadgeDetails";
import { clearToken } from "./store/slices/authenticationSlice";
import NewSideBar from "./scenes/global/NewSideBar";
import useWindowSize from "./hooks/useWindowSize";
import TransactionView from "./scenes/transactions/Details/TransactionView.jsx";
import TransactionPending from "./scenes/transactions/Details/TransactionPending.jsx";
import WithDrawView from "./scenes/transactions/Details/WithDrawView.jsx";
import WithDrawPending from "./scenes/transactions/Details/WithDrawPending.jsx";
import { PageTimeTracker } from "./components/common/PageTimeTracker.jsx";
import { NotFound } from "./components/NotFound.jsx";
import sidebarButton from "./svg/separate.svg";
import { toggleSidebar } from "./store/slices/sidebarSlice.js";
import SerialDetails from "./scenes/Inventory/requests/SerialDetails.jsx";
import Fees from "./scenes/configuration/Fees.jsx";
import Voucher from "./scenes/voucher";
import VoucherDetails from "./scenes/voucher/VoucherDetails.jsx";
import NewVoucher from "./scenes/voucher/NewVoucher.jsx";
import ContactUsInfo from "./scenes/configuration/ContactUsInfo.jsx";
import TransactionLimits from "./scenes/configuration/TransactionLimits.jsx";
import AppConfiguration from "./scenes/configuration/AppConfiguration.jsx";
import SupportTicket from "./scenes/configuration/SupportTicket.jsx";
import Referral from "./scenes/configuration/Referral.jsx";
import NotAuthorized from "./scenes/NotAuthorized/NotAuthorized.jsx";
import KYCRejectionReasons from "./scenes/KYCrejectionReasons/KYCrejectionReasons.jsx";
import AddNewRejectReason from "./scenes/KYCrejectionReasons/AddNewRejectReason.jsx";
import PrivateRoute from "./hoc/PrivateRoute.jsx";
import LiveGoldPrice from "./scenes/liveGoldPrice/LiveGoldPrice.jsx";
import AppLicense from "./scenes/app-license/AppLicense.jsx";
import AddAppLicense from "./scenes/app-license/AddAppLicense.jsx";
import LicenseDetails from "./scenes/app-license/license-details/LicenseDetails.jsx";
import EditLicense from "./scenes/app-license/license-details/EditLicense.jsx";
import AddLicenseQuestion from "./scenes/app-license/license-details/AddLicenseQuestion.jsx";
import LicenseQuestionDetails from "./scenes/app-license/license-details/LicenseQuestionDetails.jsx";
import EditLicenseQuestion from "./scenes/app-license/license-details/EditLicenseQuestion.jsx";
import PagesWithMainHeader from "./contexts/PagesWithMainHeader.jsx";
import TransactionsRejectionReason from "./scenes/TransactionsRejectionReason/index.jsx";
import AddNewTransactionRejectReason from "./scenes/TransactionsRejectionReason/AddNewTransactionRejectReason.jsx";
import ReceiveRequests from "./scenes/orders/ReceiveRequests/index.jsx";
import ReceiveRequestsDetails from "./scenes/orders/ReceiveRequests/ReceiveRequestsDetails.jsx";
import ChangePassword from "./scenes/auth/ChangePassword/index.jsx";
import DeliveryRequests from "./scenes/DeliveryRequests/index.jsx";
import DeliveryRequestDetails from "./scenes/DeliveryRequests/DeliveryRequestDetails.jsx";
import NotReceivedRequest from "./scenes/DeliveryRequests/NotReceivedRequest.jsx";
import VerifyId from "./scenes/DeliveryRequests/VerifyId.jsx";
import VerifyOtp from "./scenes/DeliveryRequests/VerifyOtp.jsx";
import UploadNationalId from "./scenes/DeliveryRequests/UploadNationalId.jsx";
import UploadReceipt from "./scenes/DeliveryRequests/UploadReceipt.jsx";
import PaperPhoto from "./scenes/DeliveryRequests/PaperPhoto.jsx";
import SuccessPage from "./scenes/DeliveryRequests/SuccessPage.jsx";
import MissedSuccessPage from "./scenes/DeliveryRequests/MissedSuccessPage.jsx";
function App() {
  const isOpen = useSelector((state) => state.sideBar.isOpen);
  const { width } = useWindowSize();
  const { isAuthenticated } = useSelector((state) => state.authentication);
  const [theme, colorMode] = useMode();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleTheSidebar = () => {
    dispatch(toggleSidebar());
  };
  const noSidebarPaths = [
    "/forget-password",
    "/ResetPassword",
    "/register",
    "/deactivatedAdmin",
    "/ChangePassword",
    "/not-authorized",
  ];
  useEffect(() => {
    // when the storage change
    window.addEventListener("storage", () => {
      const token = localStorage.getItem("token");
      if (!token) {
        dispatch(clearToken());
        navigate("/login", { replace: true });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, pathname, navigate]);

  return (
    <TransactionsProvider>
      <PageTimeTracker />

      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="flex ">
            {isAuthenticated && !noSidebarPaths.includes(pathname) && (
              <NewSideBar />
            )}
            <main
              className={`flex-grow transition-all duration-300 
    ${
      isAuthenticated && !noSidebarPaths.includes(pathname) && width > 700
        ? isOpen
          ? "ml-[18rem]"
          : "ml-[5rem]"
        : ""
    }`}
              style={{
                width:
                  isAuthenticated &&
                  !noSidebarPaths.includes(pathname) &&
                  width > 700
                    ? isOpen
                      ? "calc(100% - 18rem)"
                      : "calc(100% - 5rem)"
                    : "100%",
              }}
            >
              <img
                src={sidebarButton}
                alt="sidebar"
                width={30}
                onClick={toggleTheSidebar}
                className={`fixed z-50 top-10 cursor-pointer -ml-[1rem] ${
                  width > 700 ? "" : "hidden"
                }
                ${
                  isAuthenticated && !noSidebarPaths.includes(pathname)
                    ? ""
                    : "hidden"
                }
                `}
              />
              <Routes>
                <Route path="/AdminProfile" element={<AdminProfile />} />
                <Route path="/not-authorized" element={<NotAuthorized />} />
                <Route path="*" element={<NotFound />} />

                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/login" element={<NewLogin />} />
                  <Route path="/ChangePassword" element={<ChangePassword />} />
                  <Route
                    path="/deactivatedAdmin"
                    element={<DeactivatedAdmin />}
                  />
                  <Route path="/register" element={<RegistrationForm />} />
                  <Route path="/forget-password" element={<ForgetPassword />} />
                  <Route path="/ResetPassword" element={<ResetPassword />} />
                  <Route path="/team" element={<Team />} />
                  <Route
                    path="/HandoverRequests"
                    element={<DeliveryRequests />}
                  />
                  <Route
                    path="/HandoverRequests/:id"
                    element={<DeliveryRequestDetails />}
                  />
                  <Route
                    path="/NotReceivedRequest/:id"
                    element={<NotReceivedRequest />}
                  />
                  <Route path="/VerifyId/:id" element={<VerifyId />} />
                  <Route path="/VerifyOtp/:id" element={<VerifyOtp />} />
                  <Route
                    path="/UploadNationalId/:id"
                    element={<UploadNationalId />}
                  />
                  <Route
                    path="/UploadReceipt/:id"
                    element={<UploadReceipt />}
                  />
                  <Route path="/PaperPhoto/:id" element={<PaperPhoto />} />
                  <Route path="/SuccessPage" element={<SuccessPage />} />
                  <Route
                    path="/MissedSuccessPage"
                    element={<MissedSuccessPage />}
                  />
                  <Route path="/" element={<PagesWithMainHeader />}>
                    {/* Nested routes */}
                    <Route path="users" element={<Users />} />
                    <Route path="/users/:id" element={<UserProfile />} />
                    <Route path="/deleted-users" element={<DeletedUsers />} />
                    <Route
                      path="/ReceiveRequests"
                      element={<ReceiveRequests />}
                    />

                    <Route
                      path="/ReceiveRequestsDetails/:id"
                      element={<ReceiveRequestsDetails />}
                    />
                  </Route>

                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/add-new-role" element={<ANR />} />
                  <Route path="/ViewRole/:id" element={<ViewRole />} />
                  <Route
                    path="/ViewAdminProfile/:id"
                    element={<ViewAdminProfile />}
                  />
                  <Route path="/AddNewRole" element={<AddNewRole />} />
                  <Route path="/AddNewFAQ" element={<AddNewFAQ />} />
                  <Route
                    path="/AddNewNotification"
                    element={<AddNewNotification />}
                  />
                  <Route path="/add-new-admin" element={<AddNewUser />} />
                  <Route
                    path="/Role-Categories"
                    element={<RolesCategories />}
                  />

                  <Route
                    path="/pages-permissions"
                    element={<RolePermissions />}
                  />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/blockReasons" element={<BlockReasons />} />
                  <Route
                    path="/rejectReasons"
                    element={<KYCRejectionReasons />}
                  />
                  <Route
                    path="/transactionRejectReasons"
                    element={<TransactionsRejectionReason />}
                  />
                  <Route
                    path="/AddNewRejectReason"
                    element={<AddNewRejectReason />}
                  />
                  <Route
                    path="/AddNewTransactionRejectReason"
                    element={<AddNewTransactionRejectReason />}
                  />
                  <Route path="/closedReasons" element={<ClosedReasons />} />
                  <Route
                    path="/AddClosedReason"
                    element={<AddClosedReason />}
                  />
                  <Route path="/AddNewReason" element={<AddNewReason />} />
                  <Route path="/paymentInfo" element={<PaymentInfo />} />
                  <Route path="/banks" element={<Banks />} />
                  <Route path="/ticketSubjects" element={<TicketSubjects />} />
                  <Route
                    path="/ticketQuestions"
                    element={<TicketQuestions />}
                  />

                  <Route
                    path="/AddNewTicketQuestion"
                    element={<AddNewTicketQuestion />}
                  />
                  <Route
                    path="/AddNewTicketQuestion/:id"
                    element={<AddNewTicketQuestion />}
                  />
                  <Route
                    path="/AddNewSubQuestion/:id"
                    element={<AddNewSubQuestion />}
                  />
                  <Route
                    path="/EditSubQuestion/:id"
                    element={<EditSubQuestion />}
                  />
                  <Route
                    path="/EditTicketQuestion/:id"
                    element={<EditTicketQuestion />}
                  />
                  <Route
                    path="/SubQuestionsDetails/:id"
                    element={<SubQuestionsDetails />}
                  />
                  <Route path="/tickets" element={<Tickets />} />
                  <Route
                    path="/ticketsDetails/:id"
                    element={<TicketsDetails />}
                  />
                  <Route path="/tickets/:id" element={<Tickets />} />
                  <Route path="/add-new-product" element={<AddNewProduct />} />
                  <Route
                    path="/product-details/:id"
                    element={<ProductDetailsPage />}
                  />

                  <Route path="/aboutUs" element={<About />} />
                  <Route path="/configuration" element={<Configuration />} />
                  <Route path="/fees" element={<Fees />} />
                  <Route path="/referral" element={<Referral />} />
                  <Route path="/app-config" element={<AppConfiguration />} />
                  <Route path="/support-ticket" element={<SupportTicket />} />
                  <Route path="/min-max" element={<TransactionLimits />} />
                  <Route path="/contactus" element={<ContactUsInfo />} />
                  <Route path="/voucher/:id" element={<VoucherDetails />} />
                  <Route path="/new-voucher" element={<NewVoucher />} />
                  <Route path="/terms" element={<Terms />} />
                  {/* <Route path="/AddNewTerms" element={<AddNewTerms />} /> */}
                  <Route
                    path="/delivery-locations"
                    element={<CitiesAndTowns />}
                  />
                  <Route path="/calendar" element={<Calendar />} />

                  {/* <Route path="/profile" element={<UserProfile />} /> */}
                  <Route path="/transactions/:id" element={<Transactions />} />
                  <Route path="/transactions" element={<Transactions />} />
                  <Route
                    path="/transaction-details/:id"
                    element={<TransactionView />}
                  />
                  <Route
                    path="/transaction-pending/:id"
                    element={<TransactionPending />}
                  />
                  <Route
                    path="/withdraw-details/:id"
                    element={<WithDrawView />}
                  />
                  <Route
                    path="/withdraw-pending/:id"
                    element={<WithDrawPending />}
                  />
                  <Route
                    path="/gold-transactions/:id"
                    element={<GoldTransactions />}
                  />
                  <Route
                    path="/gold-transactions"
                    element={<GoldTransactions />}
                  />
                  <Route
                    path="/fractionsTransfer"
                    element={<FractionsTransfer />}
                  />
                  <Route path="/requests" element={<Requests />} />
                  <Route path="/stock" element={<Stock />} />
                  <Route path="/soldIngots" element={<SoldIngots />} />
                  <Route
                    path="/sellFractionsIngot"
                    element={<SellFractionsIngot />}
                  />
                  <Route path="/articles" element={<Articles />} />
                  <Route path="/AddArticle" element={<AddArticle />} />
                  <Route path="/EditArticle/:id" element={<EditArticle />} />
                  <Route path="/requests/:id" element={<OrderDetails />} />
                  <Route path="/stock/:id" element={<StockDetails />} />
                  <Route path="/orders" element={<Orders />} />

                  <Route path="/pendingOrders" element={<PendingOrders />} />

                  <Route
                    path="/pendingOrders/:id"
                    element={<PendingOrdersDetails />}
                  />
                  <Route
                    path="/onboarding-screens"
                    element={<AppManagement />}
                  />

                  <Route path="/notifications" element={<Notifications />} />
                  <Route
                    path="/notifications/:id"
                    element={<NotificationDetails />}
                  />
                  <Route
                    path="/users-permissions"
                    element={<UserPermissions />}
                  />
                  <Route
                    path="/admin-permission"
                    element={<ResourcePermissions />}
                  />
                  <Route path="/app-badge" element={<AppBadge />} />
                  <Route path="/add-badge" element={<AddBadge />} />
                  <Route
                    path="/badge-details/:id"
                    element={<AppBadgeDetails />}
                  />
                  <Route
                    path="/SerialDetails/:id"
                    element={<SerialDetails />}
                  />
                  <Route path="/voucher" element={<Voucher />} />
                  <Route path="/live-gold-price" element={<LiveGoldPrice />} />
                  <Route path="/app-license" element={<AppLicense />} />
                  <Route path="/add-app-license" element={<AddAppLicense />} />
                  <Route path="/license/:id" element={<LicenseDetails />} />
                  <Route path="/edit-license/:id" element={<EditLicense />} />
                  <Route
                    path="/add-license-question/:id"
                    element={<AddLicenseQuestion />}
                  />
                  <Route
                    path="/license-question/:id"
                    element={<LicenseQuestionDetails />}
                  />
                  <Route
                    path="/edit-license-question/:id"
                    element={<EditLicenseQuestion />}
                  />
                </Route>
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </TransactionsProvider>
  );
}

export default App;
