import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DeliveryMainHeader from "../../components/DeliveryMainHeader";
import { imageBaseUrl } from "../../services/config";
import {
  useSendReceiveGoldCheckOtp,
  useUploadEditAgent,
} from "../../services/apis/deliveryRequests";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import exports from "../../svg/exports.svg";
import { useImageStore } from "../../zustand/useImageStore";
import ReusableSnackbar from "../../components/ReusableSnackbar";

const PaperPhoto = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  const dispatch = useDispatch();
  const { mutate } = useUploadEditAgent();
  const { uploadedImages, setUploadedImage, clearImages, clearNationalId } =
    useImageStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const [previewUrls, setPreviewUrls] = useState({
    receive_paper_photo: null,
  });
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

  const handleImageUpload = (e, key) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        console.log("Please upload a valid image file.");
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        console.log("File size should not exceed 10 MB.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(key, file); // Save the file in Zustand store
        setPreviewUrls((prev) => ({
          ...prev,
          [key]: URL.createObjectURL(file),
        }));
      };
      reader.readAsDataURL(file); // Read as base64 URL
    }
  };
  useEffect(() => {
    // Cleanup dynamically created URLs
    return () => {
      Object.values(previewUrls).forEach((url) => {
        if (url) URL.revokeObjectURL(url);
      });
    };
  }, [previewUrls]);

  const handleSubmit = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const {
      national_photo,
      receipt_first_photo,
      receipt_second_photo,
      receive_paper_photo,
    } = uploadedImages;

    if (
      !national_photo ||
      !receipt_first_photo ||
      !receipt_second_photo ||
      !receive_paper_photo
    ) {
      setError("Please upload all required images.");
      setIsSubmitting(false);
      return;
    }

    // Prepare FormData for binary upload
    const formData = new FormData();
    formData.append("national_photo", national_photo);
    formData.append("receipt_first_photo", receipt_first_photo);
    formData.append("receipt_second_photo", receipt_second_photo);
    formData.append("receive_paper_photo", receive_paper_photo);

    const data = {
      status: 4,
      otp: item?.otp,
    };

    formData.append("data", JSON.stringify(data));

    mutate(
      { id: item.id, body: formData },
      {
        onSuccess: () => {
          console.log("Upload successful!");
          clearImages();
          clearNationalId();
          navigate(`/SuccessPage`); // Redirect on success
          setIsSubmitting(false); // Re-enable button
        },
        onError: (error) => {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message,
            })
          );
          console.log("Upload failed:", error?.response?.data);
          setIsSubmitting(false); // Re-enable button on error
        },
      }
    );
  };

  return (
    <div className="w-full h-full pb-[8%]">
      <DeliveryMainHeader
        label={"رفع إيصال الاستلام"}
        back={true}
        onClick={() => navigate(-1)}
      />
      <main className="w-full" dir={"rtl"}>
        <div className="bg-red  flex items-center flex-col justify-center mt-6 ">
          <div className="w-[90%] flex items-center flex-col justify-center mt-10 ">
            <p className="text-[#333333] text-base font-ibmArabic">
              يرجى رفع صورة واضحة لإيصال الاستلام. تأكد من وضوح جميع التفاصيل في
              الإيصال وأن الصورة غير ضبابية أو محجوبة.
            </p>
            <div className="w-full mt-8">
              <p className="text-xs text-[#595959] font-normal font-ibmArabic">
                إيصال الاستلام
              </p>
              <div className="border-2 border-dashed border-[#917244] rounded-xl p-8 py-12 flex flex-col items-center gap-4 mt-3 relative">
                {uploadedImages.receive_paper_photo ? (
                  <img
                    src={URL.createObjectURL(
                      uploadedImages.receive_paper_photo
                    )}
                    alt="Front ID"
                    className="w-full h-auto rounded-xl"
                  />
                ) : (
                  <>
                    <img src={exports} alt="export" width={24} height={24} />
                    <p className="text-[#404040] text-xs font-normal font-ibmArabic">
                      إيصال الاستلام
                    </p>
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  capture="environment"
                  onChange={(e) => handleImageUpload(e, "receive_paper_photo")}
                />
              </div>
            </div>
          </div>
          <div className="bg-red w-full flex items-center flex-col justify-center mt-6">
            <Button
              disabled={isSubmitting}
              onClick={() => {
                handleSubmit(item);
              }}
              sx={{
                "&:hover": { backgroundColor: "#917244" },
                backgroundColor: "#917244",
                width: "90%",
                height: "48px",
                borderRadius: "12px",
                color: "#ffffff",
                fontSize: "16px",
                marginTop: "5%",
                fontFamily: "ibmArabic",
              }}
            >
              {isSubmitting ? "جار الرفع..." : "رفع و إكمال التسليم"}
            </Button>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
                backgroundColor: "#ffffff",
                width: "90%",
                height: "48px",
                borderRadius: "12px",
                color: "#917244",
                border: "2px solid #917244",
                fontSize: "16px",
                marginTop: "5%",
                fontFamily: "ibmArabic",
              }}
            >
              رجوع
            </Button>
          </div>
        </div>
      </main>
      <ReusableSnackbar />
    </div>
  );
};

export default PaperPhoto;
