import { create } from "zustand";

export const useImageStore = create((set) => ({
  nationalId: "", // Store national ID
  setNationalId: (id) => set({ nationalId: id }), // Function to update it
  clearNationalId: () => set({ nationalId: "" }), // Function to reset it

  uploadedImages: {
    national_photo: null,
    receipt_first_photo: null,
    receipt_second_photo: null,
    receive_paper_photo: null,
  },

  setUploadedImage: (key, image) =>
    set((state) => ({
      uploadedImages: { ...state.uploadedImages, [key]: image },
    })),

  clearImages: () =>
    set({
      uploadedImages: {
        national_photo: null,
        receipt_first_photo: null,
        receipt_second_photo: null,
        receive_paper_photo: null,
      },
    }),
}));
