import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomDialog from "../../../components/CustomDialog";
import mark from "../../../svg/newdanger.svg";
import {
  useFetchSerialsByWeight,
  useUpdateDetailsReceiveRequests,
} from "../../../services/apis/ReceiveGold";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const PickupDialog = ({
  handleDialogPickupClose,
  pickupDialogOpen,
  setPickupDialogOpen,
  requestId,
  refetch,
  DetailsData,
}) => {
  console.log("DetailsData ", DetailsData);

  const {
    data: pickupData,
    isFetching,
    refetch: refetchPickup,
  } = useFetchSerialsByWeight(Number(DetailsData?.weight));
  //   console.log("pickupData ", pickupData);
  const [isEditing, setIsEditing] = useState(false);
  const [location, setLocation] = useState("");

  useEffect(() => {
    if (DetailsData?.receive_location) {
      setLocation(DetailsData.receive_location);
    }
  }, [DetailsData?.receive_location]);
  const [selectedSerials, setSelectedSerials] = useState([]);
  const [receiveTime, setReceiveTime] = useState(null);
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };
  const handleDateChange = (newValue) => {
    setReceiveTime(newValue);
    // if (onReceiveTimeChange) {
    //   onReceiveTimeChange(newValue);
    // }
  };
  const handleChange = (event) => {
    setSelectedSerials(event.target.value);
    // console.log("selectedSerials ", event.target.value);
  };
  const handleDelete = (serialToDelete) => {
    setSelectedSerials((prev) =>
      prev.filter((serial) => serial !== serialToDelete)
    );
  };
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const mutation = useUpdateDetailsReceiveRequests();
  const dispatch = useDispatch();
  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  const handleConfirm = () => {
    const formateStartDate = dayjs(receiveTime)
      .subtract(
        timezoneOffset >= 0 ? timezoneOffset : Math.abs(timezoneOffset),
        "hours"
      )
      .format("YYYY-MM-DDTHH:mm:ss");
    setIsSubmitting(true);
    // console.log("payload ", selectedSerials, formateStartDate, location);
    mutation.mutate(
      {
        id: requestId,
        body: {
          status: 3,
          serials: selectedSerials,
          receive_time: receiveTime,
          receive_location: location,
        },
      },
      {
        onSuccess: () => {
          setIsSubmitting(false);
          setPickupDialogOpen(false);
          setIsEditing(false);
          setSelectedSerials([]);
          setReceiveTime(null);
          refetch();
          setSuccessDialogOpen(true);
        },
        onError: (error) => {
          setIsSubmitting(false);
          dispatch(
            setData({
              openSnack: true,
              message:
                error.response.data.message || "Failed to update request.",
            })
          );
        },
      }
    );
  };
  const handleClose = () => {
    setPickupDialogOpen(false);
    setIsEditing(false);
    setSelectedSerials([]);
    setReceiveTime(null);
  };
  return (
    <>
      <Dialog
        maxWidth="700px"
        open={pickupDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "700px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "10%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 3,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={handleClose}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            {DetailsData?.status === "2" ? (
              <p className="text-[#404040] flex justify-start w-[100%] font-medium text-xl ">
                Change Request Status
              </p>
            ) : (
              <p className="text-[#404040] flex justify-start w-[100%] font-medium text-xl ">
                Reschedule Pickup
              </p>
            )}

            <Box
              sx={{
                width: "80px",
                height: "80px",
              }}
            >
              <img src={mark} alt="mark" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {DetailsData?.status === "2" ? (
                <Box
                  sx={{
                    color: "#404040",
                    fontWeight: "500",
                    fontSize: "24px",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  Are you sure you want to mark this Request as Ready for Pickup
                  ?
                </Box>
              ) : (
                <Box
                  sx={{
                    color: "#404040",
                    fontWeight: "500",
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  The user has missed the scheduled pickup time. Please choose a
                  new date and time for the pickup and update their request.
                </Box>
              )}
            </Box>
            <p className="text-[#404040] text-sm flex self-start font-medium ">
              Select Serial Number
            </p>
            <FormControl
              sx={{
                width: "100%",
                borderWidth: 0,
                borderRadius: "9px",
                marginTop: "-15px",
              }}
              size="small"
            >
              <Select
                placeholder="Select Weight"
                multiple
                value={selectedSerials}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    notched={false}
                    label="Select Weight"
                    sx={{
                      height: "50px",
                      borderWidth: 0,
                      borderRadius: "9px",
                      backgroundColor: "#F5F5F5",
                      "& legend": { display: "none" }, // Hide border label effect
                      "& fieldset": { top: 0 },
                    }}
                  />
                }
                renderValue={(selected) => (
                  <Stack gap={1} direction="row" flexWrap="wrap">
                    {selected.map((serial) => (
                      <Chip
                        key={serial}
                        label={serial}
                        onDelete={() => handleDelete(serial)}
                        deleteIcon={
                          <CancelIcon
                            style={{ color: "#FF453A" }}
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                      />
                    ))}
                  </Stack>
                )}
              >
                {pickupData?.map((item) => (
                  <MenuItem key={item.serial} value={item.serial}>
                    {item.serial}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                }}
              >
                Pickup Address
              </Typography>
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                  height: "48px",
                  borderRadius: "12px",
                  backgroundColor: !isEditing ? "#F1F1F1" : "#F5F5F5",
                }}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                disabled={!isEditing}
                InputProps={{
                  endAdornment: !isEditing && (
                    <InputAdornment position="end">
                      <svg
                        onClick={() => {
                          setIsEditing(!isEditing);
                        }}
                        cursor={"pointer"}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                          stroke="#333333"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.0418 3.02025L8.16183 10.9003C7.86183 11.2003 7.56183 11.7903 7.50183 12.2203L7.07183 15.2303C6.91183 16.3203 7.68183 17.0803 8.77183 16.9303L11.7818 16.5003C12.2018 16.4403 12.7918 16.1403 13.1018 15.8403L20.9818 7.96025C22.3418 6.60025 22.9818 5.02025 20.9818 3.02025C18.9818 1.02025 17.4018 1.66025 16.0418 3.02025Z"
                          stroke="#333333"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.9102 4.15039C15.5802 6.54039 17.4502 8.41039 19.8502 9.09039"
                          stroke="#333333"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <div className="w-full">
              <h2 className="text-[#404040] text-sm font-medium mb-1">
                Select Pickup Date & Time
              </h2>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  disablePast
                  sx={{
                    "& fieldset": { border: "none" },
                    width: "100%",
                    background: "#f5f5f5",
                    borderRadius: "12px",
                  }}
                  //   placeholder="Select Pickup Date & Time"
                  value={receiveTime}
                  onChange={handleDateChange}
                  slotProps={{
                    textField: {
                      placeholder: "Select Pickup Date & Time",
                      fullWidth: true,
                    },
                  }}
                  //   renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </div>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleConfirm}
                disabled={
                  isSubmitting ||
                  !location ||
                  !receiveTime ||
                  !selectedSerials.length
                }
              >
                Confirm
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <CustomDialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        title={"Request Status has been changed Successfully"}
        message=""
        buttonText="Back to Receive Requests"
      />
    </>
  );
};

export default PickupDialog;
