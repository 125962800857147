import { Box, IconButton, InputBase } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { ItemsColumns } from "../../../components/common/Columns";
import { debounce } from "../../../services/helpers";
import Header from "../../../components/Header";
import AutoRefresh from "../../../components/common/AutoRefresh";
import useReceiveRequestsStore from "../../../zustand/ReceiveRequestsStore";
import NewTable from "../../../components/common/NewTable";
import { useFetchAllReceiveRequests } from "../../../services/apis/ReceiveGold";
import { handlePageChange } from "../../../services/utils/filters";
const ReceiveRequests = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // Hook to manage URL query parameters

  const handleRowClick = (row) => {
    navigate(`/ReceiveRequestsDetails/${row.id}`);
  };
  const [searchInput, setSearchInput] = useState(
    searchParams.get("query") || ""
  );
  const [queryParameters, setQueryParameters] = useState({
    date_from: searchParams.get("date_from") || null,
    date_to: searchParams.get("date_to") || null,
    // query:  "",
    query: searchParams.get("query") || "",
    limit: Number(searchParams.get("limit")) || 10,
    page: Number(searchParams.get("page")) || 0,
  });
  const {
    data: receiveGoldData,
    isFetching,
    refetch: refetchReceiveGold,
  } = useFetchAllReceiveRequests(queryParameters);
  const { toggleListen, setToggleListen } = useReceiveRequestsStore();
  //   const { addEventListener, removeEventListener } = useGlobalSocket();

  //   useEffect(() => {
  //     if (toggleListen) {
  //       refetch();
  //       addEventListener("users-update", ({ user_id }) => {
  //         refetch();
  //       });
  //       return () => {q
  //         removeEventListener("users-update", () => {});
  //       };
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [addEventListener, toggleListen]);
  const newUsersColumns = ItemsColumns([
    {
      field: "id",
      headerName: "Request ID",
      flex: 2,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        return <p>0{params?.row?.phone}</p>;
      },
    },
    { field: "ingot_count", headerName: "Quantity", flex: 2 },
    {
      field: "status",
      headerName: "Request Status",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        const statusMap = {
          1: {
            text: "New Request",
            color: "text-[#917244]  border-l-2  border-[#917244]",
          },
          2: {
            text: "Preparing for Pickup",
            color: "text-[#FFC107]  border-l-2 border-[#FFC107]",
          },
          3: {
            text: "Ready for Pickup",
            color: "text-[#17A2B8]  border-l-2 border-[#17A2B8]",
          },
          4: {
            text: "Received",
            color: "text-[#28A745]  border-l-2 border-[#28A745]",
          },
          5: {
            text: "Canceled",
            color: "text-[#DC3545]  border-l-2 border-[#DC3545] ",
          },
          6: {
            text: "Pickup Missed",
            color: "text-[#DD670D]  border-l-2 border-[#DD670D] ",
          },
          7: {
            text: "Ready for Pickup",
            color: "text-[#17A2B8]  border-l-2 border-[#17A2B8] ",
          },
        };

        const { text, color } = statusMap[params.row?.status] || {
          text: "Unknown",
          color: "bg-gray-500 text-white",
        };

        return <div className={`px-2 py-1   ${color}`}>{text}</div>;
      },
    },
    { field: "created", headerName: "Request Date", date: true, flex: 2 },
  ]);

  // Debounced handler
  const handleSearch = useCallback(
    debounce((value) => {
      setQueryParameters((prev) => ({
        ...prev,
        query: value,
      }));
    }, 500),
    []
  );
  useEffect(() => {
    const params = new URLSearchParams();

    Object.entries(queryParameters).forEach(([key, value]) => {
      if (value !== null && value !== "" && value !== "all") {
        if (Array.isArray(value) && value.length > 0) {
          params.append(key, value.join(","));
        } else {
          params.append(key, value);
        }
      }
    });

    setSearchParams(params);
  }, [queryParameters, setSearchParams]);
  // useEffect(() => {
  //   const queryFromURL = searchParams.get("query") || "";
  //   setQueryParameters((prev) => ({
  //     ...prev,
  //     query: queryFromURL,
  //   }));
  // }, [searchParams]);
  return (
    <Box>
      <Header title="Receive Requests" marginL={"25px"} />
      <div
        className="bg-white mx-4 rounded-xl"
        style={{
          boxShadow: "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
        }}
      >
        <AutoRefresh
          setToggleListen={setToggleListen}
          toggleListen={toggleListen}
        />
        <div className=" m-8 md:flex items-center justify-between">
          <div className="flex items-center rounded-xl bg-[#f5f5f5] p-2 w-[95%] md:w-3/5">
            <IconButton type="button" aria-label="search">
              <img alt="search-normal" src={`../../assets/search-normal.png`} />
            </IconButton>
            <InputBase
              value={searchInput} // ✅ Update UI instantly
              onChange={(event) => {
                const value = event.target.value;
                setSearchInput(value); // ✅ Let user type freely
                handleSearch(value); // ✅ Debounce API request
              }}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search using Phone number , email or National ID.... "
            />
          </div>
        </div>
        <div className="">
          <NewTable
            setLimit={setQueryParameters}
            limit={queryParameters.limit}
            centerHeader
            columns={newUsersColumns}
            isLoading={isFetching}
            data={receiveGoldData?.result}
            totalItems={receiveGoldData?.totalItems}
            totalPages={receiveGoldData?.totalPages}
            handlePageChange={(newPage) =>
              handlePageChange(
                newPage,
                queryParameters,
                setQueryParameters,
                setSearchParams
              )
            }
            handleRowClick={handleRowClick}
            currentPage={queryParameters}
          />
        </div>
      </div>
    </Box>
  );
};

export default ReceiveRequests;
