import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DeliveryMainHeader from "../../components/DeliveryMainHeader";
import { imageBaseUrl } from "../../services/config";
import { useSendReceiveGoldCheckOtp } from "../../services/apis/deliveryRequests";
import { useDispatch } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import exports from "../../svg/exports.svg";
import { useImageStore } from "../../zustand/useImageStore";

const UploadReceipt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location.state;
  console.log("items 321 ", item);
  const dispatch = useDispatch();
  const checkOtpMutation = useSendReceiveGoldCheckOtp();
  const [uploadedReceipt, setUploadedReceipt] = useState({});
  const { uploadedImages, setUploadedImage } = useImageStore();

  const [nationalId, setNationalId] = useState("");
  const isButtonDisabled =
    !uploadedImages.receipt_first_photo || !uploadedImages.receipt_second_photo;
  const [previewUrls, setPreviewUrls] = useState({
    receipt_first_photo: null,
    receipt_second_photo: null,
  });
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB

  const handleImageUpload = (e, key) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        console.log("Please upload a valid image file.");
        return;
      }
      if (file.size > MAX_FILE_SIZE) {
        console.log("File size should not exceed 10 MB.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(key, file); // Save the file in Zustand store
        setPreviewUrls((prev) => ({
          ...prev,
          [key]: URL.createObjectURL(file),
        }));
      };
      reader.readAsDataURL(file); // Read as base64 URL
    }
  };
  useEffect(() => {
    // Cleanup dynamically created URLs
    return () => {
      Object.values(previewUrls).forEach((url) => {
        if (url) URL.revokeObjectURL(url);
      });
    };
  }, [previewUrls]);
  const handleNavigation = (item) => {
    console.log("clicked ", item);
    navigate(`/PaperPhoto/${item.id}`, {
      state: { ...item, uploadedImages },
    });
  };
  const handleVerifyNavigation = (item) => {
    console.log("clicked ", item);
    navigate(`/VerifyId/${item.id}`, { state: item });
  };
  return (
    <div className="w-full h-full pb-[8%]">
      <DeliveryMainHeader
        label={"رفع الفاتورة "}
        back={true}
        onClick={() => navigate(-1)}
      />
      <main className="w-full" dir={"rtl"}>
        <div className="bg-red  flex items-center flex-col justify-center mt-6 ">
          <div className="w-[90%] flex items-center flex-col justify-center mt-10 ">
            <p className="text-[#333333] text-base font-ibmArabic">
              يُرجى التقاط صورة واضحة للفاتورة التي سلمتها، والتي تتكون من
              ورقتين. تأكد من وضوح التفاصيل في كل ورقة وأن الصور غير ضبابية أو
              محجوبة.
            </p>
            <div className="w-full mt-8">
              <p className="text-xs text-[#595959] font-normal font-ibmArabic">
                الورقة الاولي
              </p>
              <div className="border-2 border-dashed border-[#917244] rounded-xl p-8 py-12 flex flex-col items-center gap-4 mt-3 relative">
                {uploadedImages.receipt_first_photo ? (
                  <img
                    src={URL.createObjectURL(
                      uploadedImages.receipt_first_photo
                    )}
                    // src={previewUrls.receipt_first_photo}
                    alt="receipt_first_photo"
                    className="w-full h-auto rounded-xl"
                  />
                ) : (
                  <>
                    <img src={exports} alt="export" width={24} height={24} />
                    <p className="text-[#404040] text-xs font-normal font-ibmArabic">
                      الورقة الاولي
                    </p>
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  capture="environment"
                  onChange={(e) => handleImageUpload(e, "receipt_first_photo")}
                />
              </div>
            </div>
            <div className="w-full mt-6">
              <p className="text-xs text-[#595959] font-normal font-ibmArabic">
                الورقة الثانيه
              </p>
              <div className="border-2 border-dashed border-[#917244] rounded-xl p-8 py-12 flex flex-col items-center gap-4 mt-3 relative">
                {uploadedImages.receipt_second_photo ? (
                  <img
                    src={URL.createObjectURL(
                      uploadedImages.receipt_second_photo
                    )}
                    // src={previewUrls.receipt_second_photo}
                    alt="receipt_second_photo"
                    className="w-full h-auto rounded-xl"
                  />
                ) : (
                  <>
                    <img src={exports} alt="export" width={24} height={24} />
                    <p className="text-[#404040] text-xs font-normal font-ibmArabic">
                      الورقة الثانيه
                    </p>
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  capture="environment"
                  onChange={(e) => handleImageUpload(e, "receipt_second_photo")}
                />
              </div>
            </div>
          </div>
          <div className="bg-red w-full flex items-center flex-col justify-center mt-6">
            <Button
              disabled={isButtonDisabled}
              onClick={() => {
                handleNavigation(item);
              }}
              sx={{
                "&:hover": { backgroundColor: "#917244" },
                backgroundColor: "#917244",
                width: "90%",
                height: "48px",
                borderRadius: "12px",
                color: "#ffffff",
                fontSize: "16px",
                marginTop: "5%",
                fontFamily: "ibmArabic",
              }}
            >
              رفع
            </Button>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
                backgroundColor: "#ffffff",
                width: "90%",
                height: "48px",
                borderRadius: "12px",
                color: "#917244",
                border: "2px solid #917244",
                fontSize: "16px",
                marginTop: "5%",
                fontFamily: "ibmArabic",
              }}
            >
              رجوع
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UploadReceipt;
