import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomDialog from "../../../components/CustomDialog";
import mark from "../../../svg/newdanger.svg";
import CustomTextArea from "../../../components/CustomTextArea";
import { useCancelDetailsReceiveRequests } from "../../../services/apis/ReceiveGold";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import ReusableSnackbar from "../../../components/ReusableSnackbar";

const CancelRequestDialog = ({
  setCancelDialogOpen,
  CancelDialogOpen,
  requestId,
  refetch,
}) => {
  const [cancelReason, setCancelReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [successCancelDialogOpen, setSuccessCancelDialogOpen] = useState(false);
  const mutation = useCancelDetailsReceiveRequests();

  const handleCancelRequest = async () => {
    setIsSubmitting(true);

    mutation.mutate(
      { id: requestId, body: { reason: cancelReason } },
      {
        onSuccess: () => {
          setIsSubmitting(false);
          setCancelDialogOpen(false);
          setSuccessCancelDialogOpen(true);
          refetch();
        },
        onError: (error) => {
          setIsSubmitting(false);
          dispatch(
            setData({
              openSnack: true,
              message:
                error.response.data.message || "Failed to cancel the request.",
            })
          );
        },
      }
    );
  };

  return (
    <>
      <Dialog
        maxWidth="700px"
        open={CancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "24px",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "700px",
            height: "auto",
            borderRadius: "24px",
            background: "#FFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5%",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              height: "auto",
              width: "80%",
              borderRadius: "24px",
              display: "flex",
              flexDirection: "column",
              gap: 3,
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
              <IoIosCloseCircleOutline
                onClick={() => setCancelDialogOpen(false)}
                color="#4C4C4C"
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
              />
            </Box>
            <p className="text-[#404040] flex justify-start w-[100%] font-medium text-2xl ">
              Cancel Request
            </p>

            <Box
              sx={{
                width: "80px",
                height: "80px",
              }}
            >
              <img src={mark} alt="mark" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  color: "#404040",
                  fontWeight: "500",
                  fontSize: "24px",
                  display: "flex",
                }}
              >
                Are you sure you want to Cancel this Request?
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#4C4C4C",
                  marginBottom: "5px",
                }}
              >
                Enter Cancel Reason
              </Typography>
              <CustomTextArea
                style={{
                  minHeight: "80px",
                  maxHeight: "200px",
                  maxWidth: "100%",
                  minWidth: "100%",
                  backgroundColor: "#FFF",
                  borderRadius: "9px",
                  boxShadow: "none",
                  border: "1px solid #917244",
                }}
                name="cancelReason"
                placeholder="Enter Cancel Reason"
                value={cancelReason}
                onChange={(event) => setCancelReason(event.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <Button
                disabled={!cancelReason.trim() || isSubmitting}
                variant="contained"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  background: "#917244",
                  height: "48px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "#917244",
                  },
                }}
                onClick={handleCancelRequest}
              >
                {isSubmitting ? "Submitting..." : "Confirm"}
              </Button>

              <Button
                variant="outlined"
                sx={{
                  width: "200px",
                  borderRadius: "9px",
                  height: "48px",
                  color: "#917244",
                  fontWeight: 600,
                  borderColor: "#917244",
                  "&:hover": {
                    borderColor: "#917244",
                  },
                }}
                onClick={() => {
                  setCancelDialogOpen(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <CustomDialog
        open={successCancelDialogOpen}
        onClose={() => setSuccessCancelDialogOpen(false)}
        title={"Request Status has been Canceled Successfully"}
        message=""
        buttonText="Back to Receive Requests"
      />
      <ReusableSnackbar />
    </>
  );
};

export default CancelRequestDialog;
