import { create } from "zustand";

const useUserFilterData = create((set) => ({
  date_from: "",
  date_to: "",
  kyc_status: [], // For document status filter
  is_online: [], // For online status filter
  user_groups: [], // For user status filter (active/blocked)
  goldTransactionType: [], // For gold Transaction Type
  providerType: [], // For gold provider Type
  orderStatus: [],
  stockStatus: [],
  saleStatus: [],
  status: [],
  type: [],
  setDateFrom: (date) => set({ date_from: date }),
  setDateTo: (date) => set({ date_to: date }),
  setKycStatus: (status) => set({ kyc_status: status }), // Set document status filter
  setOnlineStatus: (status) => set({ is_online: status }), // Set online status filter
  setUserGroups: (groups) => set({ user_groups: groups }), // Set user groups filter
  setGoldTransactionType: (status) => set({ goldTransactionType: status }), // Set gold Transaction Type
  setProviderType: (status) => set({ providerType: status }), // Set gold Transaction Type
  setOrderStatus: (status) => set({ orderStatus: status }), // Set gold Transaction Type
  setStockStatus: (status) => set({ stockStatus: status }), // Set Stock Status
  setSaleStatus: (status) => set({ saleStatus: status }), // Set Sale Status
  setStatus: (status) => set({ status: status }), // Set Status
  setType: (status) => set({ type: status }), // Set type
}));

export default useUserFilterData;
