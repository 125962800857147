import React from "react";
import Done from "../../svg/Done.svg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MissedSuccessPage = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(`/HandoverRequests`);
  };
  return (
    <div className="w-full h-screen flex items-center justify-center  bg-[#FAFAFA]">
      <main
        className="flex items-center justify-center flex-col w-[90%]"
        dir={"rtl"}
      >
        <div className="w-[250px] h-[200px]">
          <img src={Done} alt="Done" />
        </div>
        <div className="">
          <p className="text-[#404040] font-ibmArabic font-medium text-2xl text-center">
            تم الإرسال بنجاح
          </p>
          <p className="text-[#404040] font-ibmArabic font-medium text-base mt-4 text-center">
            تم إرسال السبب بنجاح. سنقوم بمراجعة التفاصيل واتخاذ الإجراءات
            اللازمة.
          </p>
        </div>
        <Button
          onClick={() => {
            handleNavigation();
          }}
          sx={{
            "&:hover": { backgroundColor: "#917244" },
            backgroundColor: "#917244",
            width: "90%",
            height: "48px",
            borderRadius: "12px",
            color: "#ffffff",
            fontSize: "16px",
            marginTop: "32px",
            fontFamily: "ibmArabic",
            fontWeight: "500",
          }}
        >
          القائمة الرئيسية
        </Button>
      </main>
    </div>
  );
};

export default MissedSuccessPage;
