import { create } from "zustand";
import { persist } from "zustand/middleware";

const useReceiveRequestsStore = create(
  persist(
    (set) => ({
      toggleListen: false,
      setToggleListen: (value) => set({ toggleListen: value }),
    }),
    {
      name: "auto-refresh-requests",
    }
  )
);

export default useReceiveRequestsStore;
